import { REACT_APP_API_BASE_URL, REACT_APP_API_GATEWAY_BASE_URL } from 'shared/config/config';
import { isUndefined, serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const accountSettings = '/v2/genericProxyRequest/';
const accountSettingsAPI = new BaseService(accountSettings);
const getBotAndInteractions = '/getBotAndInteractions';
const getBotAndInteractionsAPI = new BaseService(getBotAndInteractions);
const profileApiUrl = '/admin/profile/';
const profileApi = new BaseService(profileApiUrl);
const apiGatetWayGeneralProxyApi = new BaseService(REACT_APP_API_GATEWAY_BASE_URL);

export const API = {
	savePasswordSettings(data) {
		const {
			invalidAttempt,
			passwordExpiry,
			passwordHistory,
			isOwnerAccount,
			allowRestrictSpecification,
			ipsConfig,
		} = data;

		const params = {
			maxLoginAttempts: invalidAttempt,
			maxPasswordAge: passwordExpiry,
			passwordHistoryCount: passwordHistory,
			allowRestrictSpecification: !isOwnerAccount ? 'NA' : allowRestrictSpecification,
			ipsConfig,
			targetApi: 'passwordSettings',
		};

		return accountSettingsAPI.putRequest(params, serialize(params, '?'));
	},

	getPasswordSettings() {
		const params = {
			targetApi: 'passwordSettings',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	setCompanyUrl(companyUrl) {
		const params = {
			targetApi: 'setCompanyUrl',
			companyUrl,
		};

		return accountSettingsAPI.postRequest({}, serialize(params, '?'));
	},
	updatePassword(oldPassword, newPassword, email) {
		const updatePasswordAPI = new BaseService('/genericProxyRequest/');
		const payload = {
			oldPassword,
			password: newPassword,
			isEnabled: true,
			target_url: 'changePassword',
		};

		return updatePasswordAPI.putRequest(payload);
	},
	getBotAndInteractions() {
		return getBotAndInteractionsAPI.getRequest();
	},
	getUsageDetails() {
		const getUsageDetailsAPI = new BaseService(accountSettings, {
			headers: {
				customerId: '{{customerId}}',
			},
		});
		const params = {
			moduleName: 'ENGATI_CONNECT',
			targetApi: 'getUsageDetails',
		};

		return getUsageDetailsAPI.getRequest(serialize(params, '?'));
	},

	getActiveCs(userid) {
		const params = {
			module: 'ENGATI_CONNECT',
			targetApi: 'getActiveCs',
			replaceable_params: {
				user_id: userid,
			},
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},

	getAllTeamMembers(page, size) {
		const params = {
			module: 'ENGATI_CONNECT',
			targetApi: 'getAccountUserDetails',
			size,
			page: page - 1,
			sort: 'updatedOn,desc',
			userStatus: 'ACTIVE',
		};

		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},

	getAllTeamMemberFiltered(bot, roles, page, size, agent) {
		const botRefs = Array.isArray(bot) ? bot.join(',') : '';
		const rolesArray = Array.isArray(roles) ? roles.join(',') : '';
		const params = {
			module: 'ENGATI_CONNECT',
			targetApi: 'getAccountUserDetails',
			botRefs,
			roles: rolesArray,
			size,
			page: page - 1,
			sort: 'updatedOn,desc',
			userStatus: agent,
		};

		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},

	savePaymentAlertSettings(data) {
		const params = {
			targetApi: 'savePaymentAlertSettings',
		};

		return apiGatetWayGeneralProxyApi.postRequest(data, serialize(params, '?'));
	},
	getPaymentAlertSettings() {
		const params = {
			targetApi: 'getPaymentAlertSettings',
		};

		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	getStripeCustomerPortal() {
		const params = {
			targetApi: 'getStripeCustomerPortal',
		};

		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	getMonthlyActiveUsers() {
		const getUsageDetailsAPI = new BaseService(accountSettings, {
			headers: {
				customerId: '{{customerId}}',
			},
		});
		const params = {
			targetApi: 'getUsageDetailsBundle',
		};

		return getUsageDetailsAPI.getRequest(serialize(params, '?'));
	},
	getPreviousMonthMauDetails() {
		const params = {
			targetApi: 'getPreviousMonthMauDetails',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getPaymentHistory(date) {
		const params = {
			params: {
				fromDate: date[0],
				toDate: date[1],
				page: 0,
				size: 25,
				sort: 'createdOn,desc',
			},
		};
		const getPaymentHistoryUrl = `${REACT_APP_API_BASE_URL}/payment/history${serialize(params, '?')}`;
		const getPaymentHistoryApi = new BaseService(getPaymentHistoryUrl, {});

		return getPaymentHistoryApi.getRequest();
	},
	getInvoiceUrl(data) {
		const url = new BaseService('/get-invoice-url');
		const params = {
			paymentId: data.paymentId,
			paymentMethod: data.paymentMethod,
			paymentType: data.paymentType,
		};

		return url.getRequest(serialize(params, '?'));
	},
	postImage(data) {
		const formData = new FormData();
		formData.append('file', data);

		return new BaseService(`${REACT_APP_API_BASE_URL}/upload`).postRequest(formData);
	},
	updateProfile(data) {
		const params = {
			targetApi: 'updateProfile',
		};
		const updateProfileUrl = `${REACT_APP_API_BASE_URL}/v2/genericProxyRequest/${serialize(params, '?')}`;
		const updateProfileApi = new BaseService(updateProfileUrl, {});

		return updateProfileApi.putRequest(data);
	},
	getThemeSettings(data) {
		const params = {
			targetApi: 'themeSettings',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	setThemeSettings({ themeValue, themePack }) {
		const params = {
			targetApi: 'themeSettings',
			contrast_mode: themeValue,
			theme_pack: themePack,
		};

		return accountSettingsAPI.putRequest(params, serialize(params, '?'));
	},
	getPricingPlans() {
		const params = {
			targetApi: 'getActivePricingPlans',
			userId: '{{userId}}',
			customerId: '{{customerId}}',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getCustomerDetails() {
		const params = {
			targetApi: 'getCustomerDetail',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	submitSalesQuery(data, salesQueryWorkflow) {
		const params = {
			targetApi: 'salesQuerySubmit',
			salesQueryWorkflow,
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	contactUs(data) {
		const params = {
			targetApi: 'contactUs',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	contactSupport: (payload) => {
		const params = {
			targetApi: 'contactSupport',
		};

		return apiGatetWayGeneralProxyApi.postRequest(payload, serialize(params, '?'));
	},
	tryForFree(data, headers) {
		const tryForFreeApi = new BaseService('/v2/genericProxyRequest/', headers);
		const params = {
			targetApi: 'enableTrialPlan',
			increaseTrialCount: 'true',
		};

		return tryForFreeApi.postRequest(data, serialize(params, '?'));
	},
	getRequestedPlanDetails(data) {
		const params = {
			targetApi: 'getSubscriptionModalData',
			targetCustomerId: data.cid,
			requestedPlan: data.plan,
			moduleName: 'ENGATI_CONNECT',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	createCustomerPlan(data) {
		const params = {
			targetApi: 'subscribeOrUpdateCustomerPlanSelfSubscription',
		};

		return accountSettingsAPI.putRequest(data, serialize(params, '?'));
	},
	fetchStripePublicKey() {
		const url = new BaseService('/genericProxyRequest/', {});
		const params = {
			target_url: 'getStripeKey',
		};

		return url.getRequest(serialize(params, '?'));
	},
	startStripeSession(data) {
		const params = {
			targetApi: 'stripeCheckout',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	startStripeSessionInSetupMode(data) {
		const params = {
			targetApi: 'stripeCheckoutInSetupMode',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	checkStripeExist() {
		const params = {
			targetApi: 'findStripeCustomer',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getAllLocales() {
		const params = {
			targetApi: 'getAllLocales',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getAllTimezones() {
		const params = {
			targetApi: 'getAllTimezones',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	userProfile(data) {
		const params = {
			targetApi: 'userProfiles',
			customerId: '{{customerId}}',
			userId: '{{userId}}',
		};

		return accountSettingsAPI.putRequest(data, serialize(params, '?'));
	},
	profileDetails() {
		return profileApi.getRequest();
	},
	resendVerificationLink(data) {
		const params = {
			targetApi: 'resendVerificationLink',
			email: data,
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	createStripeCustomer(data) {
		const params = { targetApi: 'createStripeCustomer' };

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	makeGeniusPayment(data) {
		const params = { targetApi: 'geniusPayment' };

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	updateCardDetails(tokenId) {
		const data = {
			target_url: 'updateCardDetails',
		};
		const params = {
			params: {
				cardChangeToken: tokenId.id,
			},
		};
		const url = new BaseService('/genericProxyRequest/', {});

		return url.postRequest(data, serialize(params, '?'));
	},
	startStripeSessionForGenius(data) {
		const params = {
			targetApi: 'stripeCheckoutForGenius',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	createStripeSessionForWallet(data) {
		const params = {
			targetApi: 'stripeCheckoutForWallet',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	getProviderSrc(params) {
		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	changeProviderSrc(data) {
		const params = {
			targetApi: 'changeProviderSrc',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	getCreditUsage(cid) {
		const params = {
			targetApi: 'getCreditUsage',
			replaceable_params: {
				customerId: '{{customerId}}',
			},
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getWalletUsage(cid) {
		const params = {
			targetApi: 'getWalletUsage',
			replaceable_params: {
				customerId: '{{customerId}}',
			},
		};

		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?')).then((walletDetails) => {
			let { walletBalance } = walletDetails.data?.responseObject || {};

			if (!isUndefined(walletBalance)) {
				if (walletBalance.toString().indexOf('.') !== -1) {
					walletBalance = parseFloat(walletBalance).toFixed(2);
				}
			} else {
				return { walletBalance, error: true };
			}

			return { walletBalance };
		});
	},
	getWalletStatementHistory(params) {
		return accountSettingsAPI.getRequestWithParams(params);
	},
	// change needed
	exportHistoryUsage(data) {
		const params = {
			targetApi: 'exportUsageHistory',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	fetchConversationsHistory(data) {
		const params = {
			targetApi: 'fetchConversationsHistory',
			...data,
		};

		return accountSettingsAPI.getRequestWithParams(params);
	},
	fetchUsersHistory(data) {
		const params = {
			targetApi: 'fetchUsersHistory',
			...data,
		};

		return accountSettingsAPI.getRequestWithParams(params);
	},
	getMonthlyActiveUserCount(body) {
		const params = {
			targetApi: 'getMonthlyActiveUserCount',
			...body,
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	fetchInteractionsHistory(data) {
		const params = {
			targetApi: 'fetchInteractionsHistory',
			...data,
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getCreditsRechargeHistory(params) {
		return accountSettingsAPI.getRequestWithParams(params);
	},
	exportCreditRechargeHistory(data) {
		const params = {
			targetApi: 'downloadCreditsRechargeHistory',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	getCreditsConsumptionHistory(params) {
		params.platforms = params.platforms.join();

		return accountSettingsAPI.getRequestWithParams(params);
	},
	exportCreditConsumptionHistory(data) {
		const params = {
			targetApi: 'downloadCreditsConsumptionHistory',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	getCreditsJobLastRanTime() {
		const params = {
			targetApi: 'getCreditsJobLastUpdatedOn',
		};

		return accountSettingsAPI.getRequestWithParams(params);
	},
	submitExpertAssistanceRequest(data) {
		const params = {
			targetApi: 'expertAssistanceRequest',
		};

		return apiGatetWayGeneralProxyApi.postRequest(data, serialize(params, '?'));
	},
	submitFeedbackRequest(data) {
		const params = {
			targetApi: 'submitFeedbackRequest',
		};

		return accountSettingsAPI.postRequest(data, serialize(params, '?'));
	},
	submitCreditRequest(credits) {
		const params = {
			targetApi: 'submitCreditRequest',
			creditAmount: credits,
		};

		return accountSettingsAPI.putRequest(params, serialize(params, '?'));
	},
	submitPointRequest(points, currency) {
		const params = {
			targetApi: 'submitPointRequest',
			pointAmount: points,
			currency,
		};

		return accountSettingsAPI.putRequest(params, serialize(params, '?'));
	},
	getLastRechargeCreditAmount() {
		const params = {
			targetApi: 'getLastRechargeCreditAmount',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getLastWalletBalanceAdded() {
		const params = {
			targetApi: 'getLastWalletBalanceAdded',
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getMauCountForMA(body) {
		const params = {
			targetApi: 'getMonthlyActiveUserCountForMa',
			...body,
		};

		return accountSettingsAPI.getRequest(serialize(params, '?'));
	},
	getWalletConsumptionHistory(platforms, startDate, endDate) {
		const params = {
			targetApi: 'getWalletConsumptionHistory',
			replaceable_params: {
				customerId: '{{customerId}}',
			},
			startDate,
			endDate,
		};

		return accountSettingsAPI.postRequest(platforms, serialize(params, '?'));
	},
	getRechargeLimits() {
		const params = {
			targetApi: 'getWalletMetaDataByCategory',
			category: 'MINIMUM_RECHARGE',
		};

		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
};
