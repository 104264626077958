import { useState } from 'react';
import { toaster } from 'baseui/toast';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { isValidResponseObject } from 'shared/helpers';

import { API } from 'store/AccountSettings/api';
import { fontStyleSelector } from 'store/App/User';

import ConfirmationModal from './ConfirmationModal';
import DateTimeModal from './DateTimeModal';
import ExpertAssistanceModal, { IMessageType } from './ExpertAssistanceModal';
import HelpYourselfModal from './HelpYourselfModal';
import PaymentFailureModalExpertAssistance from './PaymentFailureModalExpertAssistance';

const ExpertAssistanceForm = ({
	toggleModal,
	setDateValue,
	dateValue,
}: {
	toggleModal: Function;
	setDateValue: (date: Date) => void;
	dateValue: Date;
}) => {
	const { t } = useTranslation(['pages']);

	const [selectedMessageType, setSelectedMessageType] = useState<IMessageType>();
	const [openDateTime, setOpenDateTime] = useState<boolean>(false);
	const [openHelpYourself, setOpenHelpYourself] = useState<boolean>(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
	const [openExpertAssistance, setOpenExpertAssistance] = useState<boolean>(true);
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const [timezone, setTimezone] = useState<string>('');
	const fontStyle = useSelector(fontStyleSelector);

	const defaultValues = { messageType: null };
	const methods = useForm<any>({ defaultValues });
	const { reset } = methods;

	const handleNext = (nextModalSetter: Function) => {
		setOpenHelpYourself(false);
		setOpenExpertAssistance(false);
		setOpenDateTime(false);
		setOpenConfirmationModal(false);
		nextModalSetter(true);
	};
	const errorFn = () => {
		setModalOpen(true);
		toaster.negative(
			<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
			{}
		);
	};

	const handleSubmitForm = () => {
		const data = {
			slotStartTime: dateValue.toISOString(),
			currentUrl: `${window.location.href}`,
			agenda: selectedMessageType?.label,
		};

		API.submitExpertAssistanceRequest(data)
			.then((response: any) => {
				if (isValidResponseObject(response)) {
					window.location.href = response.data.responseObject;
				} else {
					errorFn();
				}
			})
			.catch((error: any) => {
				errorFn();
			});

		reset();
	};

	return (
		<FormProvider {...methods}>
			<ExpertAssistanceModal
				isOpen={openExpertAssistance}
				onClose={() => toggleModal(false)}
				onConfirm={() => handleNext(setOpenHelpYourself)}
				selectedMessageType={selectedMessageType}
				setSelectedMessageType={setSelectedMessageType}
			/>
			<HelpYourselfModal
				isOpen={openHelpYourself}
				onClose={() => toggleModal(false)}
				onConfirm={() => handleNext(setOpenDateTime)}
				selectedMessageType={selectedMessageType}
				setSelectedMessageType={setSelectedMessageType}
				timezone={timezone}
				setTimezone={setTimezone}
				fontStyle={fontStyle}
			/>
			<ConfirmationModal
				isOpen={openConfirmationModal}
				onClose={() => handleNext(setOpenDateTime)}
				onConfirm={() => handleNext(handleSubmitForm)}
				selectedMessageType={selectedMessageType}
				dateValue={dateValue}
			/>
			<DateTimeModal
				isOpen={openDateTime}
				onConfirm={() => handleNext(setOpenConfirmationModal)}
				onClose={() => handleNext(setOpenHelpYourself)}
				onModalClose={() => toggleModal(false)}
				dateValue={dateValue}
				setDateValue={setDateValue}
			/>
			<PaymentFailureModalExpertAssistance
				toggleModal={setModalOpen}
				isModalOpen={isModalOpen}
				setOpenConfirmationModal={setOpenConfirmationModal}
			/>
		</FormProvider>
	);
};

export default ExpertAssistanceForm;
