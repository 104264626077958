import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { LabelLarge } from 'baseui/typography';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ENGTLink from 'components/UI/ENGTLink/ENGTLink';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { SUPPORT_TYPE_OPTIONS } from 'shared/consts/consts';
import { formSelectValidation } from 'shared/helpers/broadcastHelpers';

import { IMessageType } from './ExpertAssistanceModal';

interface HelpYourselfModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	selectedMessageType: IMessageType | undefined;
	setSelectedMessageType: Function;
	timezone: string;
	setTimezone: (value: string) => void;
	fontStyle: string;
}

const HelpYourselfModal: React.FC<HelpYourselfModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	selectedMessageType,
	setSelectedMessageType,
}) => {
	const [dynamicLinks, setDynamicLinks] = useState({
		docsLink: '',
		videoLink: '',
	});

	const { t } = useTranslation(['components', 'errors', 'common']);
	const [css, theme]: any = useStyletron();

	enum SUPPORT_OPTIONS {
		WHATSAPP_CAMPAIGNS = 'whatsappCampaign',
		PLATFORM_TRAINING = 'platformTraining',
		ONBOARDING_NUMBER = 'onboardNumber',
		AUTOMATED_WORKFLOWS = 'automatedWorkflow',
	}

	const getDynamicLinks = (type: IMessageType | null) => {
		if (!type) {
			return { docsLink: '', videoLink: '' };
		}
		switch (type?.id) {
			case SUPPORT_OPTIONS.WHATSAPP_CAMPAIGNS:
				return {
					docsLink: 'https://www.engati.com/whatsapp-api', // just to test
					videoLink: 'https://gallabox.com/lp/whatsapp-marketing?',
				};
			case SUPPORT_OPTIONS.PLATFORM_TRAINING:
				return {
					docsLink: 'https://www.platformtraining.com/',
					videoLink: 'https://www.platformtraining.com/courses',
				};
			case SUPPORT_OPTIONS.ONBOARDING_NUMBER:
				return {
					docsLink: 'https://www.engati.com/whatsapp-api',
					videoLink: 'https://gallabox.com/lp/whatsapp-marketing?',
				};
			case SUPPORT_OPTIONS.AUTOMATED_WORKFLOWS:
				return {
					docsLink: 'https://www.platformtraining.com/',
					videoLink: 'https://www.platformtraining.com/courses',
				};
			default:
				return {
					docsLink: 'https://www.merriam-webster.com/dictionary/default',
					videoLink: 'https://dictionary.cambridge.org/dictionary/english/default',
				};
		}
	};

	const methods = useFormContext();
	const { control } = methods;

	const confirmBtnLabel = (
		<div>
			<span
				style={{
					fontSize: '14px',
				}}
			>
				{t('components:helpYourselfModal.book')}
			</span>
			<br />
			<span
				style={{
					fontSize: '12px',
					fontWeight: 400,
				}}
			>
				{t('components:helpYourselfModal.charges')}
			</span>
		</div>
	);

	const modalContent = (
		<>
			<LabelLarge
				overrides={{
					Block: {
						style: {
							fontWeight: '700',
							fontSize: '1.25rem',
						},
					},
				}}
			>
				{t('components:helpYourselfModal.subHeading')}
			</LabelLarge>
			<ul>
				<li>
					{t('components:needHelp.helpDocs')} :{' '}
					<ENGTLink
						link={t('common:clickHereCap')}
						url={dynamicLinks.docsLink}
						overrides={{ color: theme.colors.accent }}
					/>
					{t('components:helpYourselfModal.readBroadcast')}
				</li>
				<li>
					{t('components:helpYourselfModal.video')} :{' '}
					<ENGTLink
						link={t('common:clickHereCap')}
						url={dynamicLinks.videoLink}
						overrides={{ color: theme.colors.accent }}
					/>
					{t('components:helpYourselfModal.watchBroadcast')}
				</li>
			</ul>
			<p>
				{t('components:needHelp.needHelp')} {t('components:helpYourselfModal.bookASlot')}
			</p>
		</>
	);

	const handleDropdownChange = (option: IMessageType[]) => {
		setSelectedMessageType(option?.[0]);
		setDynamicLinks(getDynamicLinks(option[0] || null));
	};

	useEffect(() => {
		if (selectedMessageType) {
			setDynamicLinks(getDynamicLinks(selectedMessageType));
		}
	}, [selectedMessageType]);

	return (
		<ActionModal
			heading={t('components:helpYourselfModal.heading')}
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onConfirm}
			confirmBtnLabel={confirmBtnLabel}
			isConfirmBtnDisabled={false}
			buttonOverrides={{
				closeBtnCss: { paddingTop: '0.475rem !important', paddingBottom: '0.375rem !important' },
				confirmBtnCss: { paddingTop: '0.375rem !important', paddingBottom: '0.375rem !important' },
			}}
			footerOverrideCss={{ paddingTop: '0 !important' }}
		>
			<Controller
				control={control}
				name='messageType'
				rules={{
					validate: (value) => formSelectValidation(value),
					required: t('errors:requiredErrorMessage').toString(),
				}}
				render={({ ref, value }) => (
					<FormSelect
						name='messageType'
						inputRef={ref}
						options={SUPPORT_TYPE_OPTIONS()}
						selectedValue={value || selectedMessageType}
						onChange={handleDropdownChange}
						popupIndex={100}
					/>
				)}
			/>
			{modalContent}
		</ActionModal>
	);
};

export default HelpYourselfModal;
