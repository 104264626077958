import { addMonths } from 'date-fns';

import { REACT_APP_BASE_URL } from 'shared/config/config';
import { IBrandingContext, IMessageFilters, ISelectValue } from 'shared/consts/types';
import { CHANNELS, LLM_PROVIDERS, SHORTCUT_QUICK_FILTER } from 'shared/enum';
import { LoginMethods } from 'shared/enum/trackingEnums';
import imoIcon from 'shared/icons/ico_im.svg';

import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

import { i18nHelper as t } from 'i18nHelper';

import { DOC_LINKS_V2 } from './DockLinks';
import { IObjProps, IPrimaryNavItems, IPrimaryShopifyNavItems } from './types';

const categoryLabels = 'components:saveAsTemplateModal.categoryLabels';

export const YES_OR_NO_FIELDS = [
	'conversation.is_live_chat_enabled',
	'conversation.is_bot_enabled',
	'is_banned',
	'user.is_banned',
];

export const SELECT_FIELD = ['conversation_due_by', 'conversation_start_date'];

export const ACTION_TYPES: IObjProps = {
	join: 'video_join',
	cancel: 'video_cancel',
};

export const ENGATI_BRANDING_TITLE = 'Engati';

export const TABLE_OVERRIDE = {
	wrapper: {
		marginLeft: 0,
		marginRight: 0,
		flexGrow: 1,
		width: '87.5% !important',
	},
};

export const messageSearchQueryLs = 'message_search_query_';

export const WhatsAppQuickFilter: any = [
	SHORTCUT_QUICK_FILTER.ONE_HOUR_WA_LIMIT,
	SHORTCUT_QUICK_FILTER.THREE_HOURS_WA_LIMIT,
	SHORTCUT_QUICK_FILTER.SIX_HOURS_WA_LIMIT,
];

export const AVATAR_BG_COLORS = ['#77CCD1', '#FFAC0A', '#A1D946', '#D993D6', '#EE5D0B'];

export const SPLIT_BROADCAST_COLORS = [
	'#008D9F',
	'#00ACD9',
	'#066B25',
	'#0974FF',
	'#2684FF',
	'#2C2DB0',
	'#4CAF50',
	'#50C39A',
	'#6749FB',
	'#C837AB',
	'#D993D6',
	'#DA2F47',
	'#E91E63',
	'#EE5D0B',
	'#FF543E',
	'#FF9800',
];

export const MEDIA_TYPE_MAP: IObjProps = {
	VIDEO: 'mp4',
	IMAGE: 'jpg,jpeg,png,gif',
	FILE: 'doc,docx,xls,xlsx,ppt,pptx,pdf,zip,rar,txt,csv',
	AUDIO: 'mp3',
};

export const ALIGNMENT_OPTIONS = [
	{
		id: 'LEFT',
		label: 'Left',
	},
	{
		id: 'CENTER',
		label: 'Center',
	},
];

export const HEADER_OPTIONS = (i18nHelper: any) => [
	{
		id: 'TEXT_WITH_IMAGE',
		label: i18nHelper('pages:configure.display.style.iconWithText'),
	},
	{
		id: 'IMAGE',
		label: i18nHelper('pages:configure.display.style.image'),
	},
];

export const ALL_AVAILABLE_PLANS = [
	'ENGATI_STARTER',
	'ENGATI_PROFESSIONAL',
	'SHOPIFY_STANDARD',
	'ENGATI_BUSINESS',
	'SHOPIFY_PREMIUM',
	'ENGATI_ENTERPRISE',
	'SHOPIFY_ENTERPRISE',
	'ENGATI_ENTERPRISE_WA',
	'WOOCOMMERCE_ACCELERATOR',
	'SHOPIFY_ACCELERATOR',
	'WA_ACCELERATOR_TRIAL',
	'SHOPIFY_PRO_2024',
];

export const USAGE_HISTORY_TABS = (t: any) => [
	t('pages:accountSettings.usageDetailsPage.usageHistory.conversations'),
	t('pages:accountSettings.usageDetailsPage.usageHistory.uniqueUsers'),
	t('pages:accountSettings.usageDetailsPage.usageHistory.interactions'),
	t('pages:accountSettings.usageDetailsPage.usageHistory.users'),
];

export const ANGULAR_DEV_BASE_URL = 'http://localhost:9090';

export const PLAN_MAPPING: any = {
	ENGATI_STARTER: 'Starter',
	ENGATI_PROFESSIONAL: 'Professional',
	ENGATI_BUSINESS: 'Business',
	ENGATI_ENTERPRISE: 'Enterprise',
	ENGATI_WA_ACCELERATOR: 'Accelerator',
};

export const SHOPIY_ONBOARDING_STEPS = {
	public: {
		getStarted: 1,
		botSelect: 1,
		planSetup: 2,
		botSetup: 3,
		teamSetup: 4,
		storeSetup: 5,
	},
	private: {
		getStarted: 1,
		botSelect: 1,
		botSetup: 2,
		teamSetup: 3,
		storeSetup: 4,
	},
};

export const SHOPIFY_PLAN_MAPPING: any = {
	SHOPIFY_STANDARD: 'Standard',
	SHOPIFY_PREMIUM: 'Premium',
	SHOPIFY_ENTERPRISE: 'Enterprise',
};

export const SUPPORT_BOT_KEY = {
	PROD: '664f9b34e13145a7',
	QA: '7348c7d447d0464e',
	DEV: 'd805aaa2938a4d30',
};

export const CATEGORIES = (t: any) => [
	{
		id: '1',
		label: t(`${categoryLabels}.customerSupport`),
	},
	{
		id: '2',
		label: t(`${categoryLabels}.ecommerce`),
	},
	{
		id: '3',
		label: t(`${categoryLabels}.education`),
	},
	{
		id: '4',
		label: t(`${categoryLabels}.entertainment`),
	},
	{
		id: '5',
		label: t(`${categoryLabels}.marketing`),
	},
	{
		id: '6',
		label: t(`${categoryLabels}.travel`),
	},
	{
		id: '7',
		label: t(`${categoryLabels}.finance`),
	},
	{
		id: '8',
		label: t(`${categoryLabels}.games`),
	},
	{
		id: '9',
		label: t(`${categoryLabels}.HR`),
	},
	{
		id: '10',
		label: t(`${categoryLabels}.lifestyle`),
	},
	{
		id: '11',
		label: t(`${categoryLabels}.news`),
	},
	{
		id: '12',
		label: t(`${categoryLabels}.personal`),
	},
	{
		id: '13',
		label: t(`${categoryLabels}.productivityAndUtilities`),
	},
	{
		id: '14',
		label: t(`${categoryLabels}.sports`),
	},
	{
		id: '15',
		label: t(`${categoryLabels}.others`),
	},
];

export const PLAN_DISPLAY_NAME_MAPPING: any = {
	ENGATI_STARTER: 'ENGATI_STARTER',
	ENGATI_PROFESSIONAL: 'ENGATI_PROFESSIONAL',
	ENGATI_BUSINESS: 'ENGATI_BUSINESS',
	ENGATI_ENTERPRISE: 'ENGATI_ENTERPRISE',
};

export const AVAILABLE_FOR_MAPPING: any = {
	ALL: 'All',
	DIRECT_CUSTOMERS: 'Direct Customers',
	SUBSET_OF_ACCOUNT: 'Sub-set of Accounts',
};

export const PIE_COLORS = [
	'#EE5D0B',
	'#FFAC0A',
	'#A1D946',
	'#77CCD1',
	'#D993D6',
	'#DE3163',
	'#FFBBA9',
	'#E77308',
	'#CB11A2',
	'#1B8EF8',
];

export const ALLOWED_ATTACHMENT_SIZE = 25000000;
export const INTEGRATION_FILE_UPLOAD_SIZE = 52428800;
export const ALLOWED_EMAIL_ATTACHMENT_SIZE = 26214400;
export const ALLOWED_COGNITIVE_SEARCH_FILE_UPLOAD_MAX_SIZE = 20971520;
export const ALLOWED_AUDIO_ATTACHMENTS = '.mp3';
export const ALLOWED_DOCUMENT_ATTACHMENTS = '.doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .zip, .rar, .txt, .csv,';
export const ALLOWED_FILE_ATTACHMENTS =
	'.doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .zip, .rar, .mp4, .mp3, .mpeg, .txt, .csv, .mpga';
export const ALLOWED_IMAGE_ATTACHMENTS = '.jpg, .jpeg, .png, .gif';
export const ALLOWED_VIDEO_ATTACHMENTS = '.mp4';
export const D360_ALLOWED_FILE_ATTACHMENTS = '.pdf';
export const D360_ALLOWED_IMAGE_ATTACHMENTS = '.jpg, .jpeg, .png';
export const D360_ALLOWED_VIDEO_ATTACHMENTS = '.mp4, .3gp';
export const ALLOWED_EMAIL_FILE_ATTACHMENTS =
	'.doc, .docx, .xls, .xlsx, .ppt, .pptx, .xps, .pdf, .ai, .psd, .dxf, .eps, .ps, .svg, .ttf, .zip, .rar, .tar, .gzip, .mp3, .mpeg, .mp4, .wav, .ogg, .jpeg, .jpg, .png, .gif, .bmp, .tif, .webm, .mpeg4, .3gpp, .mov, .avi, .mpegps, .wmv, .flv, .txt, .csv, .tsv';
export const ATTRIBUTE_LIST_MAX_LENGTH = 250;
export const CANVAS_CARD_TEXT_MAX_LENGTH = 35;
export const CANVAS_CARD_TEXT_MAX_LENGTH_V2 = 25;
export const NON_ENGLISH_LANGUAGE_CANVAS_CARD_TEXT_MAX_LENGTH = 16;
export const CONTACT_ALLOWED_FILE_ATTACHMENTS = 'text/csv, .csv, .xls, .xlsx';
export const BOT_PREVIEW_COLORS = {
	borderColor: '#c81e2b',
	menuTextColor: 'rgb(26, 171, 237)',
	placeholder: '#686868',
	timestampColor: '#cacaca',
};
export const LOCATION_ATTRIBUTES = ['user_geo_attribute_location'];
export const TIME_ATTRIBUTES = ['last_active_at', 'created_at'];
export const EDIT_ALLOWED = ['username', 'email', 'phone_no', 'title', 'account'];
export const EDIT_NOT_ALLOWED = [
	'Live chat category',
	'is_transfer_to_agent',
	'user.language',
	'import_user_segment',
	'user.is_banned',
	'user.fileupload.url',
	'user.fileupload.type',
	'user.channel_user_id',
	'user.user_id',
	'user.last_query',
	'user.last_active',
	'user.joined_on',
	'user.platform',
	'user.channel',
	'user_instagram.story_caption',
	'user_instagram.story_media_type',
	'user_instagram.story_media_url',
	'user_web',
	'user_facebook.post_media_url',
	'user_facebook.post_media_type',
	'user_facebook.post_media_description',
	'user_whatsapp.opted_out',
];

export const BOT_BG_TYPE = [
	{ id: 'solid', label: 'Solid' },
	{ id: 'image', label: 'Image' },
	{ id: 'gradient', label: 'Gradient' },
	{ id: 'video', label: 'Video' },
];

export const BOT_BG_TYPE_BOT_V1 = [
	{ id: 'NONE', label: 'None' },
	{ id: 'image', label: 'Image' },
	{ id: 'gradient', label: 'Gradient' },
	{ id: 'video', label: 'Video' },
];
export const BOT_FONT_FAMILIES = [
	{ id: 'arial', label: 'Arial' },
	{ id: 'brushScriptMt', label: 'Brush Script MT' },
	{ id: 'calibri', label: 'Calibri' },
	{ id: 'copperplate', label: 'Copperplate' },
	{ id: 'courierNew', label: 'Courier New' },
	{ id: 'garamond', label: 'Garamond' },
	{ id: 'helvetica', label: 'Helvetica' },
	{ id: 'indieFlower', label: 'Indie Flower' },
	{ id: 'nunito', label: 'Nunito' },
	{ id: 'openSans', label: 'Open Sans' },
	{ id: 'raleway', label: 'Raleway' },
	{ id: 'roboto', label: 'Roboto' },
	{ id: 'timesNewRoman', label: 'Times New Roman' },
	{ id: 'verdana', label: 'Verdana' },
	{ id: 'zapfChancery', label: 'Zapf Chancery' },
];
export const BUTTON_DISABLED_FILL_COLOR = '#C3C3C3';
export const BUTTON_DISABLED_LABEL_COLOR = '#FFFFFF';
export const BUTTON_PRIMARY_FILL_COLOR = '#AE1536';
export const BUTTON_SECONDARY_FILL_COLOR = '#E8E6E7';
export const BUTTON_TERTIARY_FILL_COLOR = '#FFFFFF';
export const BUTTON_PRIMARY_LABEL_COLOR = '#FFFFFF';
export const BUTTON_SECONDARY_LABEL_COLOR = '#000000';
export const BUTTON_TERTIARY_LABEL_COLOR = '#000000';
export const BUILDER_FORM_BUTTON_ID = 'builder-node-form-submit';
export const CALL_END_TONE = 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/disconnect.mp3';
export const CARD_MENU_TYPE = [
	{
		id: 'PATH',
		label: 'Path',
	},
	{
		id: 'URL',
		label: 'Url',
	},
	{
		id: 'FAQ',
		label: 'Faq',
	},
];
export const CARD_LAYOUT_OPTIONS = [
	{
		id: 'HORIZONTAL',
		label: 'Horizontal',
	},
	{
		id: 'VERTICAL',
		label: 'Vertical',
	},
];
export const CHAT_CLOSE_OPTIONS = [
	{
		id: 'top',
		label: 'Top',
	},
	{
		id: 'bottom',
		label: 'Bottom',
	},
];
export const CHAT_MENU_OPTIONS = [
	{
		id: 'yes',
		label: 'Yes',
	},
	{
		id: 'no',
		label: 'No',
	},
];
export const CHAT_MENU_TYPE = [
	{
		id: 'postback',
		label: 'Path',
	},
	{
		id: 'web_url',
		label: 'Url',
	},
];
export const CHAT_POPUP_TYPE_BOT_V2 = [
	{
		id: 'CLOSED',
		label: 'Launch icon with callout',
	},
	{
		id: 'NORMAL',
		label: 'Launch icon only',
	},
];
export const CHAT_POPUP_TYPE = [
	{
		id: 'CLOSED',
		label: 'Closed Callout',
	},
	{
		id: 'NORMAL',
		label: 'Normal',
	},
	{
		id: 'MINIMIZED',
		label: 'Minimized',
	},
];
export const BOT_POSITION_OPTIONS = [
	{
		id: 'bottomleft',
		label: 'Bottom Left',
	},
	{
		id: 'bottomright',
		label: 'Bottom Right',
	},
];
export const CHAT_TRIGGER_TYPE = [
	{
		id: 'immediate',
		label: 'Immediate',
	},
	{
		id: 'delayed',
		label: 'Delayed',
	},
];
export const CHANNEL_ICON_MAPPING: IObjProps = {
	gbm: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_gbm.svg',
	msft: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_skype.svg',
	teams: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_teams.svg',
	skypeforbusiness:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_skype_for_business.svg',
	skype: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_skype.svg',
	wechat: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_wechat.svg',
	kik: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_kik.svg',
	viber: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_viber.svg',
	telegram:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_telegram.svg',
	facebook:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_messenger.svg',
	web: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_web.svg',
	slack: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_slack.svg',
	line: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_line.svg',
	twitter:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_twitter.svg',
	whatsapp:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_whatsapp.svg',
	nexmo: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_whatsapp.svg',
	dialog360:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_whatsapp.svg',
	airtel: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_whatsapp.svg',
	clickatell:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_whatsapp.svg',
	kaleyra:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_whatsapp.svg',
	twilio: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_whatsapp.svg',
	native: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_whatsapp.svg',
	mobile: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_mobile_app.svg',
	email: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_email.svg',
	apitrigger: '/static/portalV2/images/channels/ic_channel_api.svg',
	// 'apitrigger': '/static/portalV2/images/channels/ic_channel_api.svg'
	instagram:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_instagram.svg',
	sms: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/node-icons-svg/sms.svg',
	sms_clickatell: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/node-icons-svg/sms.svg',
	sms_twilio: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/node-icons-svg/sms.svg',
	all_channels:
		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_all_channels.svg',
	rcs: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_gbm.svg',
};

export const showAllMinuteSlotsData = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
	21,
	22,
	23,
	24,
	25,
	26,
	27,
	28,
	29,
	30,
	31,
	32,
	33,
	34,
	35,
	36,
	37,
	38,
	39,
	40,
	41,
	42,
	43,
	44,
	45,
	46,
	47,
	48,
	49,
	50,
	51,
	52,
	53,
	54,
	55,
	56,
	57,
	58,
	59,
];

export enum COUNTRY_TYPE {
	SINGLE = 'single',
	MULTI = 'multi',
}

export const COUNTRY_CODES = [
	{
		id: '+1',
		label: '🇺🇸 +1',
	},
	{
		id: '+1242',
		label: '🇧🇸 +1242',
	},
	{
		id: '+1246',
		label: '🇧🇧 +1246',
	},
	{
		id: '+1264',
		label: '🇦🇮 +1264',
	},
	{
		id: '+1268',
		label: '🇦🇬 +1268',
	},
	{
		id: '+1284',
		label: '🇻🇬 +1284',
	},
	{
		id: '+1340',
		label: '🇻🇮 +1340',
	},
	{
		id: '+1441',
		label: '🇧🇲 +1441',
	},
	{
		id: '+1473',
		label: '🇬🇩 +1473',
	},
	{
		id: '+1649',
		label: '🇹🇨 +1649',
	},
	{
		id: '+1664',
		label: '🇲🇸 +1664',
	},
	{
		id: '+1670',
		label: '🇲🇵 +1670',
	},
	{
		id: '+1671',
		label: '🇬🇺 +1671',
	},
	{
		id: '+1684',
		label: '🇦🇸 +1684',
	},
	{
		id: '+1758',
		label: '🇱🇨 +1758',
	},
	{
		id: '+1767',
		label: '🇩🇲 +1767',
	},
	{
		id: '+1784',
		label: '🇻🇨 +1784',
	},
	{
		id: '+1849',
		label: '🇩🇴 +1849',
	},
	{
		id: '+1868',
		label: '🇹🇹 +1868',
	},
	{
		id: '+1869',
		label: '🇰🇳 +1869',
	},
	{
		id: '+1876',
		label: '🇯🇲 +1876',
	},
	{
		id: '+1939',
		label: '🇵🇷 +1939',
	},
	{
		id: '+20',
		label: '🇪🇬 +20',
	},
	{
		id: '+211',
		label: '🇸🇸 +211',
	},
	{
		id: '+212',
		label: '🇲🇦 +212',
	},
	{
		id: '+213',
		label: '🇩🇿 +213',
	},
	{
		id: '+216',
		label: '🇹🇳 +216',
	},
	{
		id: '+218',
		label: '🇱🇾 +218',
	},
	{
		id: '+220',
		label: '🇬🇲 +220',
	},
	{
		id: '+221',
		label: '🇸🇳 +221',
	},
	{
		id: '+222',
		label: '🇲🇷 +222',
	},
	{
		id: '+223',
		label: '🇲🇱 +223',
	},
	{
		id: '+224',
		label: '🇬🇳 +224',
	},
	{
		id: '+225',
		label: '🇨🇮 +225',
	},
	{
		id: '+226',
		label: '🇧🇫 +226',
	},
	{
		id: '+227',
		label: '🇳🇪 +227',
	},
	{
		id: '+228',
		label: '🇹🇬 +228',
	},
	{
		id: '+229',
		label: '🇧🇯 +229',
	},
	{
		id: '+230',
		label: '🇲🇺 +230',
	},
	{
		id: '+231',
		label: '🇱🇷 +231',
	},
	{
		id: '+232',
		label: '🇸🇱 +232',
	},
	{
		id: '+233',
		label: '🇬🇭 +233',
	},
	{
		id: '+234',
		label: '🇳🇬 +234',
	},
	{
		id: '+235',
		label: '🇹🇩 +235',
	},
	{
		id: '+236',
		label: '🇨🇫 +236',
	},
	{
		id: '+237',
		label: '🇨🇲 +237',
	},
	{
		id: '+238',
		label: '🇨🇻 +238',
	},
	{
		id: '+239',
		label: '🇸🇹 +239',
	},
	{
		id: '+240',
		label: '🇬🇶 +240',
	},
	{
		id: '+241',
		label: '🇬🇦 +241',
	},
	{
		id: '+242',
		label: '🇨🇬 +242',
	},
	{
		id: '+243',
		label: '🇨🇩 +243',
	},
	{
		id: '+244',
		label: '🇦🇴 +244',
	},
	{
		id: '+245',
		label: '🇬🇼 +245',
	},
	{
		id: '+246',
		label: '🇮🇴 +246',
	},
	{
		id: '+248',
		label: '🇸🇨 +248',
	},
	{
		id: '+249',
		label: '🇸🇩 +249',
	},
	{
		id: '+250',
		label: '🇷🇼 +250',
	},
	{
		id: '+251',
		label: '🇪🇹 +251',
	},
	{
		id: '+252',
		label: '🇸🇴 +252',
	},
	{
		id: '+253',
		label: '🇩🇯 +253',
	},
	{
		id: '+254',
		label: '🇰🇪 +254',
	},
	{
		id: '+255',
		label: '🇹🇿 +255',
	},
	{
		id: '+256',
		label: '🇺🇬 +256',
	},
	{
		id: '+257',
		label: '🇧🇮 +257',
	},
	{
		id: '+258',
		label: '🇲🇿 +258',
	},
	{
		id: '+260',
		label: '🇿🇲 +260',
	},
	{
		id: '+261',
		label: '🇲🇬 +261',
	},
	{
		id: '+262',
		label: '🇹🇫 +262',
	},
	{
		id: '+263',
		label: '🇿🇼 +263',
	},
	{
		id: '+264',
		label: '🇳🇦 +264',
	},
	{
		id: '+265',
		label: '🇲🇼 +265',
	},
	{
		id: '+266',
		label: '🇱🇸 +266',
	},
	{
		id: '+267',
		label: '🇧🇼 +267',
	},
	{
		id: '+268',
		label: '🇸🇿 +268',
	},
	{
		id: '+269',
		label: '🇰🇲 +269',
	},
	{
		id: '+27',
		label: '🇿🇦 +27',
	},
	{
		id: '+290',
		label: '🇸🇭 +290',
	},
	{
		id: '+291',
		label: '🇪🇷 +291',
	},
	{
		id: '+297',
		label: '🇦🇼 +297',
	},
	{
		id: '+298',
		label: '🇫🇴 +298',
	},
	{
		id: '+299',
		label: '🇬🇱 +299',
	},
	{
		id: '+30',
		label: '🇬🇷 +30',
	},
	{
		id: '+31',
		label: '🇳🇱 +31',
	},
	{
		id: '+32',
		label: '🇧🇪 +32',
	},
	{
		id: '+33',
		label: '🇫🇷 +33',
	},
	{
		id: '+34',
		label: '🇪🇸 +34',
	},
	{
		id: '+345',
		label: '🇰🇾 +345',
	},
	{
		id: '+350',
		label: '🇬🇮 +350',
	},
	{
		id: '+351',
		label: '🇵🇹 +351',
	},
	{
		id: '+352',
		label: '🇱🇺 +352',
	},
	{
		id: '+353',
		label: '🇮🇪 +353',
	},
	{
		id: '+354',
		label: '🇮🇸 +354',
	},
	{
		id: '+355',
		label: '🇦🇱 +355',
	},
	{
		id: '+356',
		label: '🇲🇹 +356',
	},
	{
		id: '+357',
		label: '🇨🇾 +357',
	},
	{
		id: '+358',
		label: '🇦🇽 +358',
	},
	{
		id: '+359',
		label: '🇧🇬 +359',
	},
	{
		id: '+36',
		label: '🇭🇺 +36',
	},
	{
		id: '+370',
		label: '🇱🇹 +370',
	},
	{
		id: '+371',
		label: '🇱🇻 +371',
	},
	{
		id: '+372',
		label: '🇪🇪 +372',
	},
	{
		id: '+373',
		label: '🇲🇩 +373',
	},
	{
		id: '+374',
		label: '🇦🇲 +374',
	},
	{
		id: '+375',
		label: '🇧🇾 +375',
	},
	{
		id: '+376',
		label: '🇦🇩 +376',
	},
	{
		id: '+377',
		label: '🇲🇨 +377',
	},
	{
		id: '+378',
		label: '🇸🇲 +378',
	},
	{
		id: '+379',
		label: '🇻🇦 +379',
	},
	{
		id: '+380',
		label: '🇺🇦 +380',
	},
	{
		id: '+381',
		label: '🇷🇸 +381',
	},
	{
		id: '+382',
		label: '🇲🇪 +382',
	},
	{
		id: '+383',
		label: '🇽🇰 +383',
	},
	{
		id: '+385',
		label: '🇭🇷 +385',
	},
	{
		id: '+386',
		label: '🇸🇮 +386',
	},
	{
		id: '+387',
		label: '🇧🇦 +387',
	},
	{
		id: '+389',
		label: '🇲🇰 +389',
	},
	{
		id: '+39',
		label: '🇮🇹 +39',
	},
	{
		id: '+40',
		label: '🇷🇴 +40',
	},
	{
		id: '+41',
		label: '🇨🇭 +41',
	},
	{
		id: '+420',
		label: '🇨🇿 +420',
	},
	{
		id: '+421',
		label: '🇸🇰 +421',
	},
	{
		id: '+423',
		label: '🇱🇮 +423',
	},
	{
		id: '+43',
		label: '🇦🇹 +43',
	},
	{
		id: '+44',
		label: '🇬🇬 +44',
	},
	{
		id: '+45',
		label: '🇩🇰 +45',
	},
	{
		id: '+46',
		label: '🇸🇪 +46',
	},
	{
		id: '+47',
		label: '🇧🇻 +47',
	},
	{
		id: '+48',
		label: '🇵🇱 +48',
	},
	{
		id: '+49',
		label: '🇩🇪 +49',
	},
	{
		id: '+500',
		label: '🇫🇰 +500',
	},
	{
		id: '+501',
		label: '🇧🇿 +501',
	},
	{
		id: '+502',
		label: '🇬🇹 +502',
	},
	{
		id: '+503',
		label: '🇸🇻 +503',
	},
	{
		id: '+504',
		label: '🇭🇳 +504',
	},
	{
		id: '+505',
		label: '🇳🇮 +505',
	},
	{
		id: '+506',
		label: '🇨🇷 +506',
	},
	{
		id: '+507',
		label: '🇵🇦 +507',
	},
	{
		id: '+508',
		label: '🇵🇲 +508',
	},
	{
		id: '+509',
		label: '🇭🇹 +509',
	},
	{
		id: '+51',
		label: '🇵🇪 +51',
	},
	{
		id: '+52',
		label: '🇲🇽 +52',
	},
	{
		id: '+53',
		label: '🇨🇺 +53',
	},
	{
		id: '+54',
		label: '🇦🇷 +54',
	},
	{
		id: '+55',
		label: '🇧🇷 +55',
	},
	{
		id: '+56',
		label: '🇨🇱 +56',
	},
	{
		id: '+57',
		label: '🇨🇴 +57',
	},
	{
		id: '+58',
		label: '🇻🇪 +58',
	},
	{
		id: '+590',
		label: '🇬🇵 +590',
	},
	{
		id: '+591',
		label: '🇧🇴 +591',
	},
	{
		id: '+592',
		label: '🇬🇾 +592',
	},
	{
		id: '+593',
		label: '🇪🇨 +593',
	},
	{
		id: '+594',
		label: '🇬🇫 +594',
	},
	{
		id: '+595',
		label: '🇵🇾 +595',
	},
	{
		id: '+596',
		label: '🇲🇶 +596',
	},
	{
		id: '+597',
		label: '🇸🇷 +597',
	},
	{
		id: '+598',
		label: '🇺🇾 +598',
	},
	{
		id: '+599',
		label: ' +599',
	},
	{
		id: '+60',
		label: '🇲🇾 +60',
	},
	{
		id: '+61',
		label: '🇦🇺 +61',
	},
	{
		id: '+62',
		label: '🇮🇩 +62',
	},
	{
		id: '+63',
		label: '🇵🇭 +63',
	},
	{
		id: '+64',
		label: '🇳🇿 +64',
	},
	{
		id: '+65',
		label: '🇸🇬 +65',
	},
	{
		id: '+66',
		label: '🇹🇭 +66',
	},
	{
		id: '+670',
		label: '🇹🇱 +670',
	},
	{
		id: '+672',
		label: '🇦🇶 +672',
	},
	{
		id: '+673',
		label: '🇧🇳 +673',
	},
	{
		id: '+674',
		label: '🇳🇷 +674',
	},
	{
		id: '+675',
		label: '🇵🇬 +675',
	},
	{
		id: '+676',
		label: '🇹🇴 +676',
	},
	{
		id: '+677',
		label: '🇸🇧 +677',
	},
	{
		id: '+678',
		label: '🇻🇺 +678',
	},
	{
		id: '+679',
		label: '🇫🇯 +679',
	},
	{
		id: '+680',
		label: '🇵🇼 +680',
	},
	{
		id: '+681',
		label: '🇼🇫 +681',
	},
	{
		id: '+682',
		label: '🇨🇰 +682',
	},
	{
		id: '+683',
		label: '🇳🇺 +683',
	},
	{
		id: '+685',
		label: '🇼🇸 +685',
	},
	{
		id: '+686',
		label: '🇰🇮 +686',
	},
	{
		id: '+687',
		label: '🇳🇨 +687',
	},
	{
		id: '+688',
		label: '🇹🇻 +688',
	},
	{
		id: '+689',
		label: '🇵🇫 +689',
	},
	{
		id: '+690',
		label: '🇹🇰 +690',
	},
	{
		id: '+691',
		label: '🇫🇲 +691',
	},
	{
		id: '+692',
		label: '🇲🇭 +692',
	},
	{
		id: '+7',
		label: '🇰🇿 +7',
	},
	{
		id: '+81',
		label: '🇯🇵 +81',
	},
	{
		id: '+82',
		label: '🇰🇷 +82',
	},
	{
		id: '+84',
		label: '🇻🇳 +84',
	},
	{
		id: '+850',
		label: '🇰🇵 +850',
	},
	{
		id: '+852',
		label: '🇭🇰 +852',
	},
	{
		id: '+853',
		label: '🇲🇴 +853',
	},
	{
		id: '+855',
		label: '🇰🇭 +855',
	},
	{
		id: '+856',
		label: '🇱🇦 +856',
	},
	{
		id: '+86',
		label: '🇨🇳 +86',
	},
	{
		id: '+880',
		label: '🇧🇩 +880',
	},
	{
		id: '+886',
		label: '🇹🇼 +886',
	},
	{
		id: '+90',
		label: '🇹🇷 +90',
	},
	{
		id: '+91',
		label: '🇮🇳 +91',
	},
	{
		id: '+92',
		label: '🇵🇰 +92',
	},
	{
		id: '+93',
		label: '🇦🇫 +93',
	},
	{
		id: '+94',
		label: '🇱🇰 +94',
	},
	{
		id: '+95',
		label: '🇲🇲 +95',
	},
	{
		id: '+960',
		label: '🇲🇻 +960',
	},
	{
		id: '+961',
		label: '🇱🇧 +961',
	},
	{
		id: '+962',
		label: '🇯🇴 +962',
	},
	{
		id: '+963',
		label: '🇸🇾 +963',
	},
	{
		id: '+964',
		label: '🇮🇶 +964',
	},
	{
		id: '+965',
		label: '🇰🇼 +965',
	},
	{
		id: '+966',
		label: '🇸🇦 +966',
	},
	{
		id: '+967',
		label: '🇾🇪 +967',
	},
	{
		id: '+968',
		label: '🇴🇲 +968',
	},
	{
		id: '+970',
		label: '🇵🇸 +970',
	},
	{
		id: '+971',
		label: '🇦🇪 +971',
	},
	{
		id: '+972',
		label: '🇮🇱 +972',
	},
	{
		id: '+973',
		label: '🇧🇭 +973',
	},
	{
		id: '+974',
		label: '🇶🇦 +974',
	},
	{
		id: '+975',
		label: '🇧🇹 +975',
	},
	{
		id: '+976',
		label: '🇲🇳 +976',
	},
	{
		id: '+977',
		label: '🇳🇵 +977',
	},
	{
		id: '+98',
		label: '🇮🇷 +98',
	},
	{
		id: '+992',
		label: '🇹🇯 +992',
	},
	{
		id: '+993',
		label: '🇹🇲 +993',
	},
	{
		id: '+994',
		label: '🇦🇿 +994',
	},
	{
		id: '+995',
		label: '🇬🇪 +995',
	},
	{
		id: '+996',
		label: '🇰🇬 +996',
	},
	{
		id: '+998',
		label: '🇺🇿 +998',
	},
];

// This is according to: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export const COUNTRY_NAME_AND_CODE_MAP = [
	{ id: 'AF', label: 'Afghanistan' },
	{ id: 'AX', label: 'Åland Islands' },
	{ id: 'AL', label: 'Albania' },
	{ id: 'DZ', label: 'Algeria' },
	{ id: 'AS', label: 'American Samoa' },
	{ id: 'AD', label: 'Andorra' },
	{ id: 'AO', label: 'Angola' },
	{ id: 'AI', label: 'Anguilla' },
	{ id: 'AQ', label: 'Antarctica' },
	{ id: 'AG', label: 'Antigua and Barbuda' },
	{ id: 'AR', label: 'Argentina' },
	{ id: 'AM', label: 'Armenia' },
	{ id: 'AW', label: 'Aruba' },
	{ id: 'AU', label: 'Australia' },
	{ id: 'AT', label: 'Austria' },
	{ id: 'AZ', label: 'Azerbaijan' },
	{ id: 'BS', label: 'Bahamas' },
	{ id: 'BH', label: 'Bahrain' },
	{ id: 'BD', label: 'Bangladesh' },
	{ id: 'BB', label: 'Barbados' },
	{ id: 'BY', label: 'Belarus' },
	{ id: 'BE', label: 'Belgium' },
	{ id: 'BZ', label: 'Belize' },
	{ id: 'BJ', label: 'Benin' },
	{ id: 'BM', label: 'Bermuda' },
	{ id: 'BT', label: 'Bhutan' },
	{ id: 'BO', label: 'Bolivia (Plurinational State of)' },
	{ id: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
	{ id: 'BA', label: 'Bosnia and Herzegovina' },
	{ id: 'BW', label: 'Botswana' },
	{ id: 'BV', label: 'Bouvet Island' },
	{ id: 'BR', label: 'Brazil' },
	{ id: 'IO', label: 'British Indian Ocean Territory' },
	{ id: 'BN', label: 'Brunei Darussalam' },
	{ id: 'BG', label: 'Bulgaria' },
	{ id: 'BF', label: 'Burkina Faso' },
	{ id: 'BI', label: 'Burundi' },
	{ id: 'CV', label: 'Cabo Verde' },
	{ id: 'KH', label: 'Cambodia' },
	{ id: 'CM', label: 'Cameroon' },
	{ id: 'CA', label: 'Canada' },
	{ id: 'KY', label: 'Cayman Islands' },
	{ id: 'CF', label: 'Central African Republic' },
	{ id: 'TD', label: 'Chad' },
	{ id: 'CL', label: 'Chile' },
	{ id: 'CN', label: 'China' },
	{ id: 'CX', label: 'Christmas Island' },
	{ id: 'CC', label: 'Cocos (Keeling) Islands' },
	{ id: 'CO', label: 'Colombia' },
	{ id: 'KM', label: 'Comoros' },
	{ id: 'CG', label: 'Congo' },
	{ id: 'CD', label: 'Congo (Democratic Republic of the)' },
	{ id: 'CK', label: 'Cook Islands' },
	{ id: 'CR', label: 'Costa Rica' },
	{ id: 'CI', label: "Côte d'Ivoire" },
	{ id: 'HR', label: 'Croatia' },
	{ id: 'CU', label: 'Cuba' },
	{ id: 'CW', label: 'Curaçao' },
	{ id: 'CY', label: 'Cyprus' },
	{ id: 'CZ', label: 'Czech Republic' },
	{ id: 'DK', label: 'Denmark' },
	{ id: 'DJ', label: 'Djibouti' },
	{ id: 'DM', label: 'Dominica' },
	{ id: 'DO', label: 'Dominican Republic' },
	{ id: 'EC', label: 'Ecuador' },
	{ id: 'EG', label: 'Egypt' },
	{ id: 'SV', label: 'El Salvador' },
	{ id: 'GQ', label: 'Equatorial Guinea' },
	{ id: 'ER', label: 'Eritrea' },
	{ id: 'EE', label: 'Estonia' },
	{ id: 'ET', label: 'Ethiopia' },
	{ id: 'FK', label: 'Falkland Islands (Malvinas)' },
	{ id: 'FO', label: 'Faroe Islands' },
	{ id: 'FJ', label: 'Fiji' },
	{ id: 'FI', label: 'Finland' },
	{ id: 'FR', label: 'France' },
	{ id: 'GF', label: 'French Guiana' },
	{ id: 'PF', label: 'French Polynesia' },
	{ id: 'TF', label: 'French Southern Territories' },
	{ id: 'GA', label: 'Gabon' },
	{ id: 'GM', label: 'Gambia' },
	{ id: 'GE', label: 'Georgia' },
	{ id: 'DE', label: 'Germany' },
	{ id: 'GH', label: 'Ghana' },
	{ id: 'GI', label: 'Gibraltar' },
	{ id: 'GR', label: 'Greece' },
	{ id: 'GL', label: 'Greenland' },
	{ id: 'GD', label: 'Grenada' },
	{ id: 'GP', label: 'Guadeloupe' },
	{ id: 'GU', label: 'Guam' },
	{ id: 'GT', label: 'Guatemala' },
	{ id: 'GG', label: 'Guernsey' },
	{ id: 'GN', label: 'Guinea' },
	{ id: 'GW', label: 'Guinea-Bissau' },
	{ id: 'GY', label: 'Guyana' },
	{ id: 'HT', label: 'Haiti' },
	{ id: 'HM', label: 'Heard Island and McDonald Islands' },
	{ id: 'VA', label: 'Holy See' },
	{ id: 'HN', label: 'Honduras' },
	{ id: 'HK', label: 'Hong Kong' },
	{ id: 'HU', label: 'Hungary' },
	{ id: 'IS', label: 'Iceland' },
	{ id: 'IN', label: 'India' },
	{ id: 'ID', label: 'Indonesia' },
	{ id: 'IR', label: 'Iran (Islamic Republic of)' },
	{ id: 'IQ', label: 'Iraq' },
	{ id: 'IE', label: 'Ireland' },
	{ id: 'IM', label: 'Isle of Man' },
	{ id: 'IL', label: 'Israel' },
	{ id: 'IT', label: 'Italy' },
	{ id: 'JM', label: 'Jamaica' },
	{ id: 'JP', label: 'Japan' },
	{ id: 'JE', label: 'Jersey' },
	{ id: 'JO', label: 'Jordan' },
	{ id: 'KZ', label: 'Kazakhstan' },
	{ id: 'KE', label: 'Kenya' },
	{ id: 'KI', label: 'Kiribati' },
	{ id: 'KP', label: "Korea (Democratic People's Republic of)" },
	{ id: 'KR', label: 'Korea (Republic of)' },
	{ id: 'KW', label: 'Kuwait' },
	{ id: 'KG', label: 'Kyrgyzstan' },
	{ id: 'LA', label: "Lao People's Democratic Republic" },
	{ id: 'LV', label: 'Latvia' },
	{ id: 'LB', label: 'Lebanon' },
	{ id: 'LS', label: 'Lesotho' },
	{ id: 'LR', label: 'Liberia' },
	{ id: 'LY', label: 'Libya' },
	{ id: 'LI', label: 'Liechtenstein' },
	{ id: 'LT', label: 'Lithuania' },
	{ id: 'LU', label: 'Luxembourg' },
	{ id: 'MO', label: 'Macao' },
	{ id: 'MK', label: 'Macedonia (the former Yugoslav Republic of)' },
	{ id: 'MG', label: 'Madagascar' },
	{ id: 'MW', label: 'Malawi' },
	{ id: 'MY', label: 'Malaysia' },
	{ id: 'MV', label: 'Maldives' },
	{ id: 'ML', label: 'Mali' },
	{ id: 'MT', label: 'Malta' },
	{ id: 'MH', label: 'Marshall Islands' },
	{ id: 'MQ', label: 'Martinique' },
	{ id: 'MR', label: 'Mauritania' },
	{ id: 'MU', label: 'Mauritius' },
	{ id: 'YT', label: 'Mayotte' },
	{ id: 'MX', label: 'Mexico' },
	{ id: 'FM', label: 'Micronesia (Federated States of)' },
	{ id: 'MD', label: 'Moldova (Republic of)' },
	{ id: 'MC', label: 'Monaco' },
	{ id: 'MN', label: 'Mongolia' },
	{ id: 'ME', label: 'Montenegro' },
	{ id: 'MS', label: 'Montserrat' },
	{ id: 'MA', label: 'Morocco' },
	{ id: 'MZ', label: 'Mozambique' },
	{ id: 'MM', label: 'Myanmar' },
	{ id: 'NA', label: 'Namibia' },
	{ id: 'NR', label: 'Nauru' },
	{ id: 'NP', label: 'Nepal' },
	{ id: 'NL', label: 'Netherlands' },
	{ id: 'NC', label: 'New Caledonia' },
	{ id: 'NZ', label: 'New Zealand' },
	{ id: 'NI', label: 'Nicaragua' },
	{ id: 'NE', label: 'Niger' },
	{ id: 'NG', label: 'Nigeria' },
	{ id: 'NU', label: 'Niue' },
	{ id: 'NF', label: 'Norfolk Island' },
	{ id: 'MP', label: 'Northern Mariana Islands' },
	{ id: 'NO', label: 'Norway' },
	{ id: 'OM', label: 'Oman' },
	{ id: 'PK', label: 'Pakistan' },
	{ id: 'PW', label: 'Palau' },
	{ id: 'PS', label: 'Palestine, State of' },
	{ id: 'PA', label: 'Panama' },
	{ id: 'PG', label: 'Papua New Guinea' },
	{ id: 'PY', label: 'Paraguay' },
	{ id: 'PE', label: 'Peru' },
	{ id: 'PH', label: 'Philippines' },
	{ id: 'PN', label: 'Pitcairn' },
	{ id: 'PL', label: 'Poland' },
	{ id: 'PT', label: 'Portugal' },
	{ id: 'PR', label: 'Puerto Rico' },
	{ id: 'QA', label: 'Qatar' },
	{ id: 'RE', label: 'Réunion' },
	{ id: 'RO', label: 'Romania' },
	{ id: 'RU', label: 'Russian Federation' },
	{ id: 'RW', label: 'Rwanda' },
	{ id: 'BL', label: 'Saint Barthélemy' },
	{ id: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
	{ id: 'KN', label: 'Saint Kitts and Nevis' },
	{ id: 'LC', label: 'Saint Lucia' },
	{ id: 'MF', label: 'Saint Martin (French part)' },
	{ id: 'PM', label: 'Saint Pierre and Miquelon' },
	{ id: 'VC', label: 'Saint Vincent and the Grenadines' },
	{ id: 'WS', label: 'Samoa' },
	{ id: 'SM', label: 'San Marino' },
	{ id: 'ST', label: 'Sao Tome and Principe' },
	{ id: 'SA', label: 'Saudi Arabia' },
	{ id: 'SN', label: 'Senegal' },
	{ id: 'RS', label: 'Serbia' },
	{ id: 'SC', label: 'Seychelles' },
	{ id: 'SL', label: 'Sierra Leone' },
	{ id: 'SG', label: 'Singapore' },
	{ id: 'SX', label: 'Sint Maarten (Dutch part)' },
	{ id: 'SK', label: 'Slovakia' },
	{ id: 'SI', label: 'Slovenia' },
	{ id: 'SB', label: 'Solomon Islands' },
	{ id: 'SO', label: 'Somalia' },
	{ id: 'ZA', label: 'South Africa' },
	{ id: 'GS', label: 'South Georgia and the South Sandwich Islands' },
	{ id: 'SS', label: 'South Sudan' },
	{ id: 'ES', label: 'Spain' },
	{ id: 'LK', label: 'Sri Lanka' },
	{ id: 'SD', label: 'Sudan' },
	{ id: 'SR', label: 'Suriname' },
	{ id: 'SJ', label: 'Svalbard and Jan Mayen' },
	{ id: 'SZ', label: 'Swaziland' },
	{ id: 'SE', label: 'Sweden' },
	{ id: 'CH', label: 'Switzerland' },
	{ id: 'SY', label: 'Syrian Arab Republic' },
	{ id: 'TW', label: 'Taiwan, Province of China' },
	{ id: 'TJ', label: 'Tajikistan' },
	{ id: 'TZ', label: 'Tanzania, United Republic of' },
	{ id: 'TH', label: 'Thailand' },
	{ id: 'TL', label: 'Timor-Leste' },
	{ id: 'TG', label: 'Togo' },
	{ id: 'TK', label: 'Tokelau' },
	{ id: 'TO', label: 'Tonga' },
	{ id: 'TT', label: 'Trinidad and Tobago' },
	{ id: 'TN', label: 'Tunisia' },
	{ id: 'TR', label: 'Turkey' },
	{ id: 'TM', label: 'Turkmenistan' },
	{ id: 'TC', label: 'Turks and Caicos Islands' },
	{ id: 'TV', label: 'Tuvalu' },
	{ id: 'UG', label: 'Uganda' },
	{ id: 'UA', label: 'Ukraine' },
	{ id: 'AE', label: 'United Arab Emirates' },
	{ id: 'GB', label: 'United Kingdom of Great Britain and Northern Ireland' },
	{ id: 'US', label: 'United States of America' },
	{ id: 'UM', label: 'United States Minor Outlying Islands' },
	{ id: 'UY', label: 'Uruguay' },
	{ id: 'UZ', label: 'Uzbekistan' },
	{ id: 'VU', label: 'Vanuatu' },
	{ id: 'VE', label: 'Venezuela (Bolivarian Republic of)' },
	{ id: 'VN', label: 'Viet Nam' },
	{ id: 'VG', label: 'Virgin Islands (British)' },
	{ id: 'VI', label: 'Virgin Islands (U.S.)' },
	{ id: 'WF', label: 'Wallis and Futuna' },
	{ id: 'EH', label: 'Western Sahara' },
	{ id: 'YE', label: 'Yemen' },
	{ id: 'ZM', label: 'Zambia' },
	{ id: 'ZW', label: 'Zimbabwe' },
];

export const DEFAULT_PAGINATION_DATA = {
	first: true,
	last: true,
	number: 0,
	numberOfElements: 0,
	size: 0,
	sort: '',
	totalElements: 0,
	totalPages: 0,
};

export const DOWNLOAD_ERROR_STATUS = [
	'FAILED',
	'PROCESSING_ERROR',
	'DATA_VALIDATION_FAILED',
	'DATA_NOT_FOUND',
	'UPLOAD_ERROR',
];
export const ENV_DOMAIN_VIDEO_MAP: IObjProps = {
	d: 'meet-dev.engati.com',
	qa: 'meet-qa.engati.com',
	p: 'meet.engati.com',
};

export const ENGT_ALERT_NOTIFICATION =
	'https://branding-resources.s3.ap-south-1.amazonaws.com/default/notification-tone.mp3';
export const ENGT_ALERT_NOTIFICATION_TIMEOUT = 3000;
export const ENGT_SAMPLE_NOTIFICATION =
	'https://branding-resources.s3.ap-south-1.amazonaws.com/default/sample-audio.mp3';
export const ENGT_CHAT_DEFAULT_WALLPAPER =
	'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/default-wallpaper/image/bg_a.svg';

export const ENGATI_RESPONSE_CODE = {
	SUCCESS: 1000,
	/* TODO Need to rename SUCCESS to STATUS_1000 across the portal */
	STATUS_200: 200,
	STATUS_300: 300,
	STATUS_201: 201,
	STATUS_1000: 1000,
	STATUS_1016: 1016,
	STATUS_9010: 9010,
	STATUS_13: 13,
	STATUS_2020: 2020,
	STATUS_2021: 2021,
	STATUS_1039: 1039,
	STATUS_22033: 22033,
	STATUS_27000: 27000,
	STATUS_24016: 24016,
	STATUS_9011: 9011,
	STATUS_2001: 2001,
	STATUS_2008: 2008,
	STATUS_2009: 2009,
	STATUS_9999: 9999,
	STATUS_1012: 1012,
	STATUS_1014: 1014,
	STATUS_1002: 1002,
	STATUS_15005: 15005,
	STATUS_29001: 29001,
	STATUS_1032: 1032,
	STATUS_1033: 1033,
	STATUS_400: 400,
	STATUS_999: 999,
	STATUS_17000: 17000,
	STATUS_17001: 17001,
	STATUS_17002: 17002,
	STATUS_24050: 24050,
	STATUS_28015: 28015,
	STATUS_28016: 28016,
	STATUS_1034: 1034,
	STATUS_3010: 3010,
	STATUS_28003: 28003,
	STATUS_2024: 2024,
	STATUS_2022: 2022,
	STATUS_1025: 1025,
	STATUS_1031: 1031,
	STATUS_1041: 1041,
	STATUS_1023: 1023,
	STATUS_1050: 1050,
	STATUS_25002: 25002,
	STATUS_1005017: 1005017,
	STATUS_40002: 40002,
	STATUS_1005019: 1005019,
	STATUS_1005020: 1005020,
};

export const ACCOUNT_TYPES = {
	CUSTOMER: 'CUSTOMER',
	RESELLER: 'RESELLER',
	AFFILIATE: 'AFFILIATE',
	MASTER_ADMIN: 'MASTER_ADMIN',
};

export const DEFAULT_LANGUAGE_CODE = 'en';

export const ENGATI_ROLES = {
	ROLE_CONNECT_OWNER: 'ROLE_CONNECT_OWNER',
	ROLE_CONNECT_SUPERVISOR: 'ROLE_CONNECT_SUPERVISOR',
	ROLE_CONNECT_AGENT: 'ROLE_CONNECT_AGENT',
	ROLE_CONNECT_BOT_BUILDER: 'ROLE_CONNECT_BOT_BUILDER',
	ROLE_CONNECT_EXECUTIVE: 'ROLE_CONNECT_EXECUTIVE',
	ROLE_KEY_PARTNER_ADMIN_BD: 'ROLE_KEY_PARTNER_ADMIN_BD',
};

export const ATTRIBUTE_CATEGORY = {
	SYSTEM: 'SYSTEM',
	CUSTOM: 'CUSTOM',
};
export const ENGATI_ROLES_NAME_MAP: any = {
	ROLE_CONNECT_OWNER: 'Owner',
	ROLE_CONNECT_SUPERVISOR: 'Supervisor',
	ROLE_CONNECT_AGENT: 'Agent',
	ROLE_CONNECT_BOT_BUILDER: 'Bot Builder',
	ROLE_CONNECT_EXECUTIVE: 'Executive',
};
export const MESSAGE_LIMIT_TIER_MAP: any = {
	tier_1k: 1000,
	tier_10k: 10000,
	tier_100k: 100000,
	unlimited: 'unlimited',
	null: 'unlimited',
	tier_unlimited: 'unlimited',
};
export const ENGTFont = 'Hind';
export const FAVICON_APPLE_TOUCH_SIZES = [57, 60, 72, 76, 114, 120, 144, 152, 180];
export const FAVICON_SIZES = [16, 32, 96];
export const INPUT_SEARCH_DEBOUNCE_VAL = 500;
export const LABEL_COLOR = '#403F42';
export const MESSAGE_TAB_HEADERS = ['new', 'active', 'unassigned', 'all'];
export const MESSAGE_TAB_HEADERS_AGENT = ['new', 'active', 'all'];
export const MESSAGE_TAB_HEADERS_EXTERNAL_LIVECHAT = ['all'];
export const MESSAGE_TAB_HEADERS_MAP = {
	new: 'new',
	active: 'active',
	unassigned: 'unassigned',
	all: 'all',
	oneviewlite: 'oneview-lite',
};
export const ANALYTICS_TAB = {
	agentPerformance: 'Agent Performance',
	newAgentPerformance: 'New Agent Performance',
};
export const TRANSLATION_STATUS = {
	SUCCESS: 'SUCCESS',
	FAILURE: 'FAILURE',
	IN_PROGRESS: 'IN_PROGRESS',
};
export const COMMENTS_SYNC_STATUS = {
	INITIATED: 'INITIATED',
	IN_PROGRESS: 'IN_PROGRESS',
	SUCCESS: 'SUCCESS',
	FAILURE: 'FAILURE',
};
export const TRANSLATED_LAST_MESSAGE_STATE = {
	PROCESSED: 'PROCESSED',
	TO_PROCESS: 'TO_PROCESS',
};
export const MESSAGE_TAB_HEADERS_NON_LC = ['all'];
export const NAVIGATION: Array<Array<IPrimaryNavItems>> = [
	['home', 'waQuickNavigator'],
	['messages', 'broadcast', 'users', 'analytics'],
	['builder', 'train', 'team', 'configure', 'integrations', 'whatsApp'],
];

export const SHOPIFY_NAVIGATION: Array<Array<IPrimaryShopifyNavItems>> = [
	['home', 'quickNavigator', 'shopifyQuickNavigator'],
	['messages', 'broadcast', 'store', 'users', 'analytics'],
	['products', 'conversationSupport', 'conversationBuilder', 'team', 'storeSettings', 'integrations', 'whatsApp'],
];

export const NAVIGATION_ACCESS_MAP: { [key in IPrimaryNavItems]: string } = {
	home: 'home',
	waQuickNavigator: 'waQuickNavigator',
	messages: 'conversation',
	builder: 'pathBuilder',
	train: 'train',
	team: 'configure.team',
	configure: 'configure',
	users: 'connectUsers',
	integrations: 'integrations',
	analytics: 'dashboard',
	accountSettings: 'account',
	broadcast: 'broadcast',
	quickGuide: 'quickGuide',
	botMode: 'botMode',
	whatsApp: 'whatsApp',
};

export const SHOPIFY_NAVIGATION_ACCESS_MAP: { [key in IPrimaryShopifyNavItems]: string } = {
	home: 'home',
	quickNavigator: 'quickNavigator',
	shopifyQuickNavigator: 'shopifyQuickNavigator',
	messages: 'conversation',
	conversationBuilder: 'pathBuilder',
	conversationSupport: 'train',
	team: 'configure.team',
	storeSettings: 'configure',
	store: 'store',
	users: 'connectUsers',
	integrations: 'integrations',
	analytics: 'dashboard',
	accountSettings: 'account',
	broadcast: 'broadcast',
	products: 'products',
	whatsApp: 'whatsApp',
};

export const SHOPIFY_ROUTES: { [key in IPrimaryShopifyNavItems]: string } = {
	home: 'home',
	quickNavigator: 'quick-navigator-ecommerce',
	shopifyQuickNavigator: 'accelerator-quick-guide-ecom',
	messages: 'messages',
	conversationBuilder: 'builder',
	conversationSupport: 'train',
	team: 'team',
	storeSettings: 'configure',
	store: 'store',
	users: 'users',
	integrations: 'integrations',
	analytics: 'analytics',
	accountSettings: 'account',
	broadcast: 'broadcast',
	products: 'products',
	whatsApp: 'whatsApp',
};

export const WooCOMMERCE_LINK: IObjProps = {
	wooCommercePluginDownloadLink: 'https://branding-resources.s3.ap-south-1.amazonaws.com/woocommerce/Engati.zip',
};

export const waManagerLink = 'https://business.facebook.com/wa/manage/';

export const PRIMARY_BACKGROUND_COLOR = '#ffffff';
export const MESSAGE_STATE_MAP = {
	ASSIGNED: 'ASSIGNED',
	AUTOMATIC_HANDLED: 'AUTOMATIC_HANDLED',
	ENGAGING: 'ENGAGING',
	HANDLED: 'HANDLED',
	PENDING_RESOLUTION: 'PENDING_RESOLUTION',
	REQUESTED: 'REQUESTED',
	END_USER_RESOLVED: 'END_USER_RESOLVED',
};
export const QUILL_REGEX_CHECK = /^(?!<p><br><\/p>)/g;
export const EXTRACT_UTC_TIMEZONE_REGEX = /([+-])(\d{2}):(\d{2})/;
export const EMPTY_QUILL_EDITOR = '<p><br></p>';
export const QUICK_REPLY_SHORTCUT = '<p><br</p>';

export const MEDIA_CAPTION_URL_CHECK =
	/^(http[s]?:\/\/)?([^:\/\s]+)(:([^\/]*))?(\/\w+\.)*([^#?\s]+)(\?([^#]*))?(\.[vV][tT][tT])$/gm;
export const EMAIL_VALID =
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* added åäöʋšžéõŋđþł these special characters that might occur in finnish URLS */
export const URL_REGEX =
	/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]+(\/[a-zA-Z0-9åäöʋšžéõŋđþł\?\=\.\-\&\/]+)+$/gm;
export const URL_REGEX_DOCUSENSE =
	/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]+(\/[a-zA-Z0-9åäöʋšžéõŋđþł\_\?\=\.\-\&\/%#]+)*$/i;
export const REFRESH_TOKEN_INTERVAL = {
	timer: 0,
};
export const MENTIO_EMAIL = /^{{[a-zA-Z0-9_.]*}}$/;
export const WHATSAPP_SOLR_QUERY =
	'platform:("WHATSAPP" OR "NEXMO"  OR "KALEYRA" OR "CLICKATELL" OR "TWILIO" OR "DIALOG360" OR "AIRTEL")';

export const SMS_SOLR_QUERY = 'platform:("SMS_CLICKATELL" OR "SMS_TWILIO")';
export const MESSAGE_SENDER_MAP = {
	user: 'user',
	bot: 'bot',
};
export const MESSAGE_TYPE_MAP = {
	text: 'TEXT',
	messageReceived: 'MESSAGE_RECEIVED',
	options: 'OPTIONS',
	audio: 'AUDIO',
	image: 'IMAGE',
	video: 'VIDEO',
	carousel: 'CAROUSEL',
	fileReceived: 'FILE_RECEIVED',
	feedBack: 'FEEDBACK',
	postBack: 'POSTBACK',
	broadcast: 'BROADCAST',
	file: 'FILE',
	card: 'CARD',
	slider: 'SLIDER',
	identity: 'IDENTITY',
	identityNode: 'IDENTITY_NODE',
	formNode: 'FORM_NODE',
	webView: 'WEB_VIEW',
	emailReceived: 'EMAIL_RECEIVED',
	email: 'EMAIL',
	agentParticipation: 'AGENT_PARTICIPATION_STATUS',
	storyReceived: 'STORY_RECEIVED',
	template: 'TEMPLATE',
	COMMENT_RECEIVED: 'COMMENT_RECEIVED',
	COMMENT: 'COMMENT',
	PRIVATE_REPLY: 'PRIVATE_REPLY',
	WA_COMMERCE_PRODUCT: 'WA_COMMERCE_PRODUCT',
	CART_RECEIVED: 'CART_RECEIVED',
	PRODUCT_MESSAGE_RECEIVED: 'PRODUCT_MESSAGE_RECEIVED',
	AD_REFERRAL_RECEIVED: 'AD_REFERRAL_RECEIVED',
	WA_PAYMENT: 'WA_PAYMENT',
	WA_CONTACT: 'WA_CONTACT',
	WA_FLOW_RECEIVED: 'WA_FLOW_RECEIVED',
	WA_FLOW: 'WA_FLOW',
};

export const SENTIMENT_ATTRIBUTE_LABEL = 'conversation_sentiment';

export const SENTIMENT_MAP: any = (t: any) => ({
	Neutral: t('components:messageFilter.SentimentNeutral'),
	Positive: t('components:messageFilter.SentimentPositive'),
	Negative: t('components:messageFilter.SentimentNegative'),
});

export const SENTIMENT_COLOR_MAP: any = (theme: any) => ({
	Neutral: theme.sentimentNeutralColor,
	Positive: theme.sentimentPositiveColor,
	Negative: theme.sentimentNegativeColor,
});

export const INTENT_MAP: any = (t: any) => ({
	PAYMENT_ENQUIRY: t('components:addEditIntentModal.paymentEnquiry'),
	ORDER_ENQUIRY: t('components:addEditIntentModal.orderEnquiry'),
	PRICE_ENQUIRY: t('components:addEditIntentModal.priceEnquiry'),
	PROMOTIONS_ENQUIRY: t('components:addEditIntentModal.promotionsEnquiry'),
	DELIVERY_ENQUIRY: t('components:addEditIntentModal.deliveryEnquiry'),
	OUT_OF_STOCK_ENQUIRY: t('components:addEditIntentModal.outOfStockEnquiry'),
	CANCEL_AND_REFUND: t('components:addEditIntentModal.cancelAndRefund'),
	PURCHASE_ENQUIRY: t('components:addEditIntentModal.purchaseEnquiry'),
	RETURN_AND_EXCHANGE: t('components:addEditIntentModal.returnAndExchange'),
});

export const REVENUE_MAP: any = (t: any) => ({
	BROADCAST: t('shopify:revenueDashboard.whatsappBroadcastsAnalytics'),
	CAMPAIGN: t('shopify:revenueDashboard.abandonedCheckoutCampaignAnalytics'),
	CTWA: t('shopify:revenueDashboard.clickToWhatsappAds'),
});

export enum ROLE {
	Owner = 'Owner',
}

export enum AGENT_STATUS {
	Offline = 'Offline',
	Online = 'Online',
}

export const INTENT_LABEL_MAP: any = (t: any) => ({
	[t('components:addEditIntentModal.paymentEnquiry')]: 'PAYMENT_ENQUIRY',
	[t('components:addEditIntentModal.promotionsEnquiry')]: 'PROMOTIONS_ENQUIRY',
	[t('components:addEditIntentModal.deliveryEnquiry')]: 'DELIVERY_ENQUIRY',
	[t('components:addEditIntentModal.orderEnquiry')]: 'ORDER_ENQUIRY',
	[t('components:addEditIntentModal.cancelAndRefund')]: 'CANCEL_AND_REFUND',
	[t('components:addEditIntentModal.returnAndExchange')]: 'RETURN_AND_EXCHANGE',
	[t('components:addEditIntentModal.priceEnquiry')]: 'PRICE_ENQUIRY',
	[t('components:addEditIntentModal.outOfStockEnquiry')]: 'OUT_OF_STOCK_ENQUIRY',
	[t('components:addEditIntentModal.purchaseEnquiry')]: 'PURCHASE_ENQUIRY',
});

export const DEFAULT_BRANDING_CONTEXT: IBrandingContext = {
	brand_title: '',
	banner_url: '',
	registration_page_image: '',
	registration_page_logo: '',
	registration_page_bg: '',
	font_style: '',
	webchat_theme_color: '',
	social_providers: [],
	auth_url: '',
	terms_of_condition: '',
	custom_css_for_login: '',
	privacy_policy: '',
};

export const FILE_EXPIRY = 720 * 60 * 60 * 1000;
export const CHANNEL_DETAILS: IObjProps = {
	email: {
		conversationName: 'Email Conversation',
		platformName: 'email',
	},
	web: {
		conversationName: 'Website Conversation',
		isGroupConversationAllowed: true,
	},
	whatsapp: {
		conversationName: 'Whatsapp Conversation',
	},
	facebook: {
		conversationName: 'Messenger Conversation',
	},
	telegram: {
		conversationName: 'Telegram Conversation',
	},
	mobile: {
		conversationName: 'Mobile Conversation',
		isGroupConversationAllowed: true,
	},
	msft: {
		conversationName: 'Skype Conversation',
	},
	teams: {
		conversationName: 'Microsoft Teams Conversation',
	},
	skypeforbusiness: {
		conversationName: 'Skype For Business Conversation',
	},
	wechat: {
		conversationName: 'Wechat Conversation',
	},
	kik: {
		conversationName: 'Kik Conversation',
	},
	viber: {
		conversationName: 'Viber Conversation',
	},
	slack: {
		conversationName: 'Slack Conversation',
	},
	line: {
		conversationName: 'Line Conversation',
	},
	twitter: {
		conversationName: 'Twitter Conversation',
	},
	im: {
		conversationName: 'IM Conversation',
	},
	nexmo: {
		conversationName: 'Whatsapp Conversation',
	},
	clickatell: {
		conversationName: 'Whatsapp Conversation',
	},
	kaleyra: {
		conversationName: 'Whatsapp Conversation',
	},
	apitrigger: {
		conversationName: 'ApiTrigger Conversation',
	},
	instagram: {
		conversationName: 'Instagram Conversation',
	},
};

export const CHANNEL_TITLE_MAPPING: any = {
	msft: 'Microsoft/Skype',
	teams: 'Microsoft Teams',
	skypeforbusiness: 'Skype For Business',
	kik: 'Kik',
	viber: 'Viber',
	telegram: 'Telegram',
	facebook: 'Messenger',
	web: 'Website',
	slack: 'Slack',
	line: 'LINE',
	twitter: 'Twitter',
	whatsapp: 'WhatsApp',
	nexmo: 'WhatsApp',
	dialog360: 'WhatsApp',
	im: 'IM',
	mobile: 'Mobile App',
	wechat: 'WeChat',
	email: 'Email',
	apitrigger: 'ApiTrigger',
	gbm: 'GBM',
	instagram: 'Instagram',
	kaleyra: 'WhatsApp',
	twilio: 'WhatsApp',
	native: 'WhatsApp',
	clickatell: 'WhatsApp',
	sms: 'SMS',
	sms_twilio: 'SMS',
	sms_clickatell: 'SMS',
	all_channels: 'All Channels',
};

export const D360_BILLIING_CATEGORIES = (t: any): IObjProps => ({
	AUTHENTICATION: t('common:Authentication'),
	MARKETING: t('common:marketing'),
	SERVICE: t('common:service'),
	UTILITY: t('common:utility'),
});

export const MONTH_NAME_MAP = (t: any): IObjProps => ({
	1: t('common:month.january'),
	2: t('common:month.febuary'),
	3: t('common:month.march'),
	4: t('common:month.april'),
	5: t('common:month.may'),
	6: t('common:month.june'),
	7: t('common:month.july'),
	8: t('common:month.august'),
	9: t('common:month.september'),
	10: t('common:month.october'),
	11: t('common:month.november'),
	12: t('common:month.december'),
});

export const OFFERING_TITLE_MAPPING: any = {
	ENGATI_PLATFORM: 'Engati Platform',
	ENGATI_FOR_SHOPIFY: 'Engati for Shopify',
};

export const DAYS_TO_MILLISECONDS = 86400000;

export const PRICING_PAGE_URL = NoColumnRouteLinks.billing;

export const NON_BROADCAST_CHANNELS = [
	'nexmo',
	'clickatell',
	'kaleyra',
	'twilio',
	'dialog360',
	'sms_clickatell',
	'airtel',
];

export const CHANNEL_NAME_ICON_MAPPING: IObjProps = {
	gbm: {
		icon: CHANNEL_ICON_MAPPING.gbm,
		name: 'GBM',
	},
	rcs: {
		icon: CHANNEL_ICON_MAPPING.rcs,
		name: 'RCS',
	},
	msft: {
		icon: CHANNEL_ICON_MAPPING.skype,
		name: 'Microsoft/Skype',
	},
	teams: {
		icon: CHANNEL_ICON_MAPPING.teams,
		name: 'Microsoft Teams',
	},

	skypeforbusiness: {
		icon: CHANNEL_ICON_MAPPING.skypeforbusiness,
		name: 'Skype For Business',
	},

	wechat: {
		icon: CHANNEL_ICON_MAPPING.wechat,
		name: 'WeChat',
	},

	kik: {
		icon: CHANNEL_ICON_MAPPING.kik,
		name: 'Kik',
	},

	viber: {
		icon: CHANNEL_ICON_MAPPING.viber,
		name: 'Viber',
	},

	telegram: {
		icon: CHANNEL_ICON_MAPPING.telegram,
		name: 'Telegram',
	},

	facebook: {
		icon: CHANNEL_ICON_MAPPING.facebook,
		name: 'Facebook Messenger',
	},

	web: {
		icon: CHANNEL_ICON_MAPPING['web'],
		name: 'Website',
	},

	slack: {
		icon: CHANNEL_ICON_MAPPING.slack,
		name: 'Slack',
	},

	line: {
		icon: CHANNEL_ICON_MAPPING.line,
		name: 'LINE',
	},

	twitter: {
		icon: CHANNEL_ICON_MAPPING.twitter,
		name: 'Twitter',
	},

	whatsapp: {
		icon: CHANNEL_ICON_MAPPING.whatsapp,
		name: 'WhatsApp',
	},
	kaleyra: {
		icon: CHANNEL_ICON_MAPPING.kaleyra,
		name: 'Kaleyra',
	},
	nexmo: {
		icon: CHANNEL_ICON_MAPPING.whatsapp,
		name: 'WhatsApp',
	},

	dialog360: {
		icon: CHANNEL_ICON_MAPPING.dialog360,
		name: 'WhatsApp',
	},

	airtel: {
		icon: CHANNEL_ICON_MAPPING.airtel,
		name: 'WhatsApp',
	},

	twilio: {
		icon: CHANNEL_ICON_MAPPING.twilio,
		name: 'WhatsApp',
	},
	native: {
		icon: CHANNEL_ICON_MAPPING.native,
		name: 'WhatsApp',
	},
	clickatell: {
		icon: CHANNEL_ICON_MAPPING.clickatell,
		name: 'WhatsApp',
	},
	mobile: {
		icon: CHANNEL_ICON_MAPPING['mobile'],
		name: 'Mobile App',
	},

	email: {
		icon: CHANNEL_ICON_MAPPING['email'],
		name: 'Email',
	},
	skype: {
		icon: CHANNEL_ICON_MAPPING.skype,
		name: 'Skype',
	},
	apitrigger: {
		icon: CHANNEL_ICON_MAPPING.apitrigger,
		name: 'ApiTrigger',
	},
	im: {
		icon: imoIcon,
		name: 'Im',
	},
	instagram: {
		icon: CHANNEL_ICON_MAPPING.instagram,
		name: 'Instagram',
	},
	sms: {
		icon: CHANNEL_ICON_MAPPING.sms,
		name: 'SMS',
	},
	sms_clickatell: {
		icon: CHANNEL_ICON_MAPPING.sms,
		name: 'SMS',
	},
	sms_twilio: {
		icon: CHANNEL_ICON_MAPPING.sms,
		name: 'SMS',
	},
	all_channels: {
		icon: CHANNEL_ICON_MAPPING.all_channels,
		name: 'All Channels',
	},
};
export const VOICE_RESPONSE_TYPES = [
	{
		id: 'MALE',
		label: 'Male',
	},
	{
		id: 'FEMALE',
		label: 'Female',
	},
];

const {
	sm,
	smo,
	sendCarousel,
	sendImage,
	sendVideo,
	sendAudio,
	sendDocument,
	feedback,
	rud,
	identity,
	slider,
	formnode,
	fileUpload,
	filterFaq,
	triggerpath,
	script,
	sendEmail,
	pause,
	pythonScript,
	decision,
	webview,
	subscribeToCampaign,
	unsubscribeToCampaign,
	zendesk,
	salesforce,
	freshdesk,
	zapier,
	googleSheets,
	randomizeMessages,
	customcard,
	setuserattribute,
	googleCalendar,
	gorgias,
	jsonAPI,
	transferToAgent,
	templateMessage,
	whatsAppFlows,
} = DOC_LINKS_V2.builder;

export const NODE_HELP_LINK: {
	[key: string]: string;
} = {
	cardNode: customcard,
	carousel: sendCarousel,
	decision,
	feedback,
	fileUpload,
	filterFAQ: filterFaq,
	freshdesk,
	formNode: formnode,
	google_calendar: googleCalendar,
	google_sheets: googleSheets,
	gorgias,
	identityNode: identity,
	jsonAPI,
	pauseNode: pause,
	pythonScriptNode: pythonScript,
	randomize: randomizeMessages,
	requestFeedback: DOC_LINKS_V2.home,
	requestUserData: rud,
	salesforce,
	scriptNode: script,
	sendAudio,
	sendEmail,
	sendImage,
	sendMessage: sm,
	sendMessageWithOptions: smo,
	sendVideo,
	setUserAttribute: setuserattribute,
	sliderNode: slider,
	sms_twilio: DOC_LINKS_V2.home,
	sms_clickatell: DOC_LINKS_V2.home,
	shopify: DOC_LINKS_V2.home,
	subscribeCampaign: subscribeToCampaign,
	triggerPath: triggerpath,
	triggerLiveChat: transferToAgent,
	unsubscribeCampaign: unsubscribeToCampaign,
	uploadFile: fileUpload,
	sendDocument,
	jsonApi: jsonAPI,
	webView: webview,
	whatsAppCommerce: DOC_LINKS_V2.home,
	zendesk,
	zapier,
	templateMessage,
	whatsAppFlows,
};

export const ENGATI_DARK_LOGO =
	'https://branding-resources.s3.ap-south-1.amazonaws.com/default/engati-simply-intelligent-cy21-dark.png';

export const IMAGE_URL_REGEX =
	/^(http[s]?:\/\/)?([^:\/\s]+)(:([^\/]*))?(\/\w+\.)*([^#?\s]+)(\?([^#]*))?(\.[jJ][pP][gG]|\.[jJ][pP][eE][gG]|\.[pP][nN][gG]|\.[gG][iI][fF])$/;

export const WHATSAPP_PLATFORMS = ['whatsapp', 'nexmo', 'clickatell', 'kaleyra', 'twilio', 'dialog360', 'airtel'];

export const WHATSAPP_PLATFORMS_CAPS = ['WHATSAPP', 'NEXMO', 'CLICKATELL', 'KALEYRA', 'TWILIO', 'DIALOG360'];

export const ROW_ACTION_SPAN_SIZE = 0.4;

export enum GROUP_CONVERSATION_STATES {
	joined = 'JOINED',
	invited = 'INVITED',
	exited = 'EXITED',
	expired = 'EXPIRED',
}

export enum ROLE_SCOPE {
	CATEGORY_CONVERSATIONS = 'CATEGORY_CONVERSATIONS',
}

export const ENGATI_SUPPORT_MAIL = 'support@engati.com';

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const PAYMENT_PLANS_MAP = {
	monthly: 'MONTHLY',
	yearly: 'YEARLY',
	starter: 'STARTER',
	professional: 'PROFESSIONAL',
	business: 'BUSINESS',
	enterprise: 'ENTERPRISE',
	whatsapp: 'WA',
};

export const PLAN_OFFERING_MAP: IObjProps = {
	SHOPIFY: 'shopify',
	SHOPIFY_PUBLIC: 'shopifyPublic',
	BASE_PLATFORM: 'base',
};

export const PLAN_OFFERING_KEYS: IObjProps = {
	SHOPIFY: 'SHOPIFY',
	SHOPIFY_PUBLIC: 'SHOPIFY_PUBLIC',
	BASE_PLATFORM: 'BASE_PLATFORM',
};

export enum OFFERING_KEY_ENUM {
	ENGATI_STARTER = 'ENGATI_STARTER',
	ENGATI_PROFESSIONAL = 'ENGATI_PROFESSIONAL',
	ENGATI_BUSINESS = 'ENGATI_BUSINESS',
	ENGATI_BUSINESS_WA = 'ENGATI_BUSINESS_WA',
	ENGATI_ENTERPRISE = 'ENGATI_ENTERPRISE',
	ENGATI_ENTERPRISE_WA = 'ENGATI_ENTERPRISE_WA',
	SHOPIFY_STANDARD = 'SHOPIFY_STANDARD',
	SHOPIFY_PREMIUM = 'SHOPIFY_PREMIUM',
	SHOPIFY_ENTERPRISE = 'SHOPIFY_ENTERPRISE',
	SHOPIFY_BASE = 'SHOPIFY_BASE',
	SHOPIFY_FREE_PACK = 'SHOPIFY_FREE_PACK',
	SHOPIFY_STARTER_PACK = 'SHOPIFY_STARTER_PACK',
	SHOPIFY_GROWTH_PACK = 'SHOPIFY_GROWTH_PACK',
	ENGATI_GROWTH_2024 = 'ENGATI_GROWTH_2024',
	ENGATI_PRO_2024 = 'ENGATI_PRO_2024',
	ENGATI_ENTERPRISE_2024 = 'ENGATI_ENTERPRISE_2024',
}

export const IGNORE_RESTRICT_INTEGRATIONS = [
	OFFERING_KEY_ENUM.ENGATI_GROWTH_2024,
	OFFERING_KEY_ENUM.ENGATI_PRO_2024,
	OFFERING_KEY_ENUM.ENGATI_ENTERPRISE_2024,
];

export enum COPY_BOT_STATUS {
	started = 'STARTED',
	failed = 'FAILED',
}

const {
	ENGATI_STARTER,
	ENGATI_PROFESSIONAL,
	ENGATI_BUSINESS,
	ENGATI_BUSINESS_WA,
	ENGATI_ENTERPRISE,
	ENGATI_ENTERPRISE_WA,
	SHOPIFY_STANDARD,
	SHOPIFY_PREMIUM,
	SHOPIFY_ENTERPRISE,
	SHOPIFY_FREE_PACK,
	SHOPIFY_STARTER_PACK,
	SHOPIFY_GROWTH_PACK,
} = OFFERING_KEY_ENUM;

export const OFFERING_KEY: IObjProps = {
	[ENGATI_STARTER]: 'starter',
	[ENGATI_PROFESSIONAL]: 'professional',
	[ENGATI_BUSINESS]: 'business',
	[ENGATI_BUSINESS_WA]: 'business',
	[ENGATI_ENTERPRISE]: 'enterprise',
	[ENGATI_ENTERPRISE_WA]: 'enterprise',
	[SHOPIFY_STANDARD]: 'standard',
	[SHOPIFY_PREMIUM]: 'premium',
	[SHOPIFY_ENTERPRISE]: 'enterprise',
	[SHOPIFY_FREE_PACK]: 'free_pack',
	[SHOPIFY_STARTER_PACK]: 'starter_pack',
	[SHOPIFY_GROWTH_PACK]: 'growth_pack',
};

export const ENGATI_WEBSITE_LINK = 'https://www.engati.com';
export const ENGATI_WEBSITE_WA_ACC_LINK = 'https://engati.webflow.io/whatsapp-campaigns';

export const CURRENCY_SYMBOL: IObjProps = {
	AED: 'د.إ',
	AFN: '؋',
	ALL: 'Lek',
	AMD: '֏',
	ANG: 'ƒ',
	AOA: 'Kz',
	ARS: '$',
	AUD: '$',
	AWG: 'ƒ',
	AZN: '₼',
	BAM: 'KM',
	BBD: '$',
	BDT: '৳',
	BGN: 'лв',
	BHD: '.د.ب',
	BIF: 'Fr',
	BMD: '$',
	BND: '$',
	BOB: 'Bs.',
	BRL: 'R$',
	BSD: '$',
	BTN: 'Nu.',
	BWP: 'P',
	BYN: 'Br',
	BZD: '$',
	CAD: '$',
	CDF: 'Fr',
	CHF: 'Fr',
	CLP: '$',
	CNY: '¥',
	COP: '$',
	CRC: '₡',
	CUC: '$',
	CUP: '$',
	CVE: '$',
	CZK: 'Kč',
	DJF: 'Fr',
	DKK: 'kr',
	DOP: '$',
	DZD: 'دج',
	EGP: '£',
	ERN: 'Nfk',
	ETB: 'Br',
	EUR: '€',
	FJD: '$',
	FKP: '£',
	GBP: '£',
	GEL: '₾',
	GGP: '£',
	GHS: '₵',
	GIP: '£',
	GMD: 'D',
	GNF: 'Fr',
	GTQ: 'Q',
	GYD: '$',
	HKD: '$',
	HNL: 'L',
	HRK: 'kn',
	HTG: 'G',
	HUF: 'Ft',
	IDR: 'Rp',
	ILS: '₪',
	IMP: '£',
	INR: '₹',
	IQD: 'ع.د',
	IRR: '﷼',
	ISK: 'kr',
	JEP: '£',
	JMD: '$',
	JOD: 'ينار',
	JPY: '¥',
	KES: 'KSh',
	KGS: 'с',
	KHR: '៛',
	KMF: 'Fr',
	KPW: '₩',
	KRW: '₩',
	KWD: 'ك',
	KYD: '$',
	KZT: '₸',
	LAK: '₭',
	LBP: 'ل.ل',
	LKR: 'Rs',
	LRD: '$',
	LSL: 'M',
	LYD: 'ل.د',
	MAD: 'د.م.',
	MDL: 'L',
	MGA: 'Ar',
	MKD: 'ден',
	MMK: 'Ks',
	MNT: '₮',
	MOP: 'P',
	MRO: 'UM',
	MRU: 'UM',
	MUR: '₨',
	MVR: 'ރ.',
	MWK: 'MK',
	MXN: '$',
	MYR: 'RM',
	MZN: 'MT',
	NAD: '$',
	NGN: '₦',
	NIO: 'C$',
	NOK: 'kr',
	NPR: '₨',
	NZD: '$',
	OMR: '﷼',
	PAB: 'B/.',
	PEN: 'S/.',
	PGK: 'K',
	PHP: '₱',
	PKR: 'Rs',
	PLN: 'zł',
	PYG: '₲',
	QAR: '﷼',
	RON: 'lei',
	RSD: 'дин.',
	RUB: '₽',
	RWF: 'Fr',
	SAR: '﷼',
	SBD: '$',
	SCR: '₨',
	SDG: 'ج.س.',
	SEK: 'kr',
	SGD: '$',
	SHP: '£',
	SLL: 'Le',
	SOS: 'S',
	SPL: '₤',
	SRD: '$',
	STN: 'Db',
	SVC: '$',
	SYP: '£',
	SZL: 'L',
	THB: '฿',
	TJS: 'SM',
	TMT: 'T',
	TND: 'د.ت',
	TOP: 'T$',
	TRY: '₺',
	TTD: '$',
	TVD: '$',
	TWD: 'NT$',
	TZS: 'TSh',
	UAH: '₴',
	UGX: 'UGX',
	USD: '$',
	UYU: '$U',
	UZS: 'soʻm',
	VES: 'Bs',
	VND: '₫',
	VUV: 'Vt',
	WST: 'T',
	XAF: 'Fr',
	XCD: '$',
	XDR: 'SDR',
	XOF: 'Fr',
	XPF: 'Fr',
	YER: '﷼',
	ZAR: 'R',
	ZMW: 'ZK',
	ZWL: '$',
};

/* We are supporting only these cloud app integration for now, can be commented out once support all */
export const SUPPORTED_INTEGRATIONS = [
	'SHOPIFY',
	'WOOCOMMERCE',
	'SHIPROCKET',
	'RAZORPAY',
	'ZENDESK',
	'FRESHDESK',
	'SMS_TWILIO',
	'GOOGLE_SHEETS',
	'GOOGLE_CALENDAR',
	'GOOGLE_ANALYTICS',
	'SALESFORCE',
	'GOOGLE_TRANSLATE',
	'GORGIAS',
	'ZAPIER',
];

export const D360_CLOUD_BASE_URL = 'https://waba-v2.360dialog.io';

export const ALL_INTEGRATIONS_LIST: any = {
	GOOGLE_SHEETS: { authType: 'OAuth2', displayName: 'Google Sheets', key: 'GOOGLE_SHEETS' },
	SHOPIFY: { authType: 'OAuth2', displayName: 'Shopify', key: 'SHOPIFY' },
	WOOCOMMERCE: { authType: 'OAuth2', displayName: 'WooCommerce', key: 'WOOCOMMERCE' },
	SHIPROCKET: { authType: 'OAuth2', displayName: 'Shiprocket', key: 'SHIPROCKET' },
	RAZORPAY: { authType: 'OAuth2', displayName: 'Razorpay', key: 'RAZORPAY' },
	ZENDESK: { authType: 'OAuth2', displayName: 'Zendesk', key: 'ZENDESK' },
	FRESHDESK: { authType: 'OAuth2', displayName: 'Freshdesk', key: 'FRESHDESK' },
	SMS_TWILIO: { authType: 'OAuth2', displayName: 'SMS', key: 'SMS_TWILIO' },
	GOOGLE_CALENDAR: { authType: 'OAuth2', displayName: 'Google Calendar', key: 'GOOGLE_CALENDAR' },
	GOOGLE_ANALYTICS: { authType: 'OAuth2', displayName: 'Google Analytics', key: 'GOOGLE_ANALYTICS' },
	SALESFORCE: { authType: 'OAuth2', displayName: 'Salesforce', key: 'SALESFORCE' },
	GOOGLE_TRANSLATE: { authType: 'OAuth2', displayName: 'Google Translate', key: 'GOOGLE_TRANSLATE' },
	GORGIAS: { authType: 'OAuth2', displayName: 'Gorgias', key: 'GORGIAS' },
	ESENSEGPT: { authType: 'OAuth2', displayName: 'eSenseGPT', key: 'ESENSEGPT' },
	GOOGLE_ADMIN: { authType: 'OAuth2', displayName: 'Google Admin', key: 'GOOGLE_ADMIN' },
	SALESTRAIL: { authType: 'OAuth2', displayName: 'Salestrail', key: 'SALESTRAIL' },
};

export const CURRENCY_MAP: IObjProps = {
	USD: 'USD',
	INR: 'INR',
};

export const PLAN_HIERARCHY: IObjProps = {
	[ENGATI_STARTER]: 1,
	[ENGATI_PROFESSIONAL]: 2,
	[ENGATI_BUSINESS]: 3,
	[ENGATI_BUSINESS_WA]: 3,
	[ENGATI_ENTERPRISE]: 4,
	[ENGATI_ENTERPRISE_WA]: 4,
	[SHOPIFY_STANDARD]: 1,
	[SHOPIFY_PREMIUM]: 2,
	[SHOPIFY_ENTERPRISE]: 3,
	[SHOPIFY_FREE_PACK]: 1,
	[SHOPIFY_STARTER_PACK]: 2,
	[SHOPIFY_GROWTH_PACK]: 3,
};

export const VISIBLE_ON_UI = 'VISIBLE_ON_UI';
export const MASTER_ADMIN_ID = 1;
export const AFFILIATE = 'AFFILIATE';

export const PRICING_PLANS_CHANNELS_MAP: IObjProps = {
	base: {
		starter: [
			CHANNEL_NAME_ICON_MAPPING.web,
			CHANNEL_NAME_ICON_MAPPING.facebook,
			CHANNEL_NAME_ICON_MAPPING.telegram,
			CHANNEL_NAME_ICON_MAPPING.kik,
			CHANNEL_NAME_ICON_MAPPING.line,
			CHANNEL_NAME_ICON_MAPPING.wechat,
			CHANNEL_NAME_ICON_MAPPING.twitter,
			CHANNEL_NAME_ICON_MAPPING.slack,
			CHANNEL_NAME_ICON_MAPPING.skype,
			CHANNEL_NAME_ICON_MAPPING.viber,
		],
		professional: [
			CHANNEL_NAME_ICON_MAPPING.web,
			CHANNEL_NAME_ICON_MAPPING.facebook,
			CHANNEL_NAME_ICON_MAPPING.telegram,
			CHANNEL_NAME_ICON_MAPPING.kik,
			CHANNEL_NAME_ICON_MAPPING.line,
			CHANNEL_NAME_ICON_MAPPING.wechat,
			CHANNEL_NAME_ICON_MAPPING.twitter,
			CHANNEL_NAME_ICON_MAPPING.slack,
			CHANNEL_NAME_ICON_MAPPING.skype,
			CHANNEL_NAME_ICON_MAPPING.viber,
		],
		business: [CHANNEL_NAME_ICON_MAPPING.whatsapp, CHANNEL_NAME_ICON_MAPPING.skypeforbusiness],
		enterprise: [CHANNEL_NAME_ICON_MAPPING.teams, CHANNEL_NAME_ICON_MAPPING.mobile],
	},
	shopify: {
		standard: [CHANNEL_NAME_ICON_MAPPING.web],
		premium: [
			CHANNEL_NAME_ICON_MAPPING.email,
			CHANNEL_NAME_ICON_MAPPING.whatsapp,
			CHANNEL_NAME_ICON_MAPPING.facebook,
			CHANNEL_NAME_ICON_MAPPING.instagram,
		],
		enterprise: [
			CHANNEL_NAME_ICON_MAPPING.mobile,
			CHANNEL_NAME_ICON_MAPPING.teams,
			CHANNEL_NAME_ICON_MAPPING.skypeforbusiness,
			CHANNEL_NAME_ICON_MAPPING.telegram,
			CHANNEL_NAME_ICON_MAPPING.kik,
			CHANNEL_NAME_ICON_MAPPING.line,
			CHANNEL_NAME_ICON_MAPPING.wechat,
			CHANNEL_NAME_ICON_MAPPING.twitter,
			CHANNEL_NAME_ICON_MAPPING.slack,
			CHANNEL_NAME_ICON_MAPPING.skype,
			CHANNEL_NAME_ICON_MAPPING.viber,
		],
	},
	shopifyPublic: {
		free_pack: [CHANNEL_NAME_ICON_MAPPING.web, CHANNEL_NAME_ICON_MAPPING.instagram],
		starter_pack: [CHANNEL_NAME_ICON_MAPPING.web, CHANNEL_NAME_ICON_MAPPING.instagram],
		growth_pack: [
			CHANNEL_NAME_ICON_MAPPING.web,
			CHANNEL_NAME_ICON_MAPPING.instagram,
			CHANNEL_NAME_ICON_MAPPING.facebook,
			CHANNEL_NAME_ICON_MAPPING.whatsapp,
			CHANNEL_NAME_ICON_MAPPING.email,
		],
	},
};

export const SCREEN_SHARE_NOTIFICATION_TYPES: any = {
	on: 'screenShareOn',
	off: 'screenShareOff',
};

export const MESSAGE_SEARCH = 'message_search_data';

export const emailChannelToolbarOptions = [
	{ font: [] },
	{
		header: [1, 2, 3, 4, 5, 6, false],
	},
	'image',
	'bold',
	'italic',
	'underline',
	{ color: [] },
	{ background: [] },
	'link',
	'code-block',
	{ list: 'ordered' },
	{ list: 'bullet' },
	{ indent: '-1' },
	{ indent: '+1' },
	'blockquote',
	{ align: [] },
	'clean',
];

export const emailChannelFormatOptions = [
	'font',
	'header',
	'image',
	'bold',
	'italic',
	'underline',
	'color',
	'background',
	'link',
	'code-block',
	'list',
	'indent',
	'blockquote',
	'align',
	'clean',
];

export const FB_ME_API = '/me?fields=gender,first_name,last_name,birthday,locale,timezone,email';

export const shopifyOnboardingV2TotalStepCount = 3;

export const shopifyOnboardingTotalStepCount = 5;

export const shopifyPrivateOnboardingStepCount = 4;

export const SHOPIFY_ENGATI_APP_INSTALL_URL = 'https://apps.shopify.com/engati';

export const AGENT_CONFIGURE_AVAILABLE_CHANNELS = ['Zendesk', 'Freshdesk', 'Salesforce', 'Salestrail'];

export const AVAILABLE_INTEGRATIONS_SETTINGS = [
	'Zendesk',
	'Freshdesk',
	'Gorgias',
	'Salesforce',
	'SMS',
	'Google Translate',
	'Google Analytics',
	'Shopify',
	'WooCommerce',
	'Shiprocket',
	'Razorpay',
	'eSenseGPT',
];

export const ENGATI_APP_NAME = 'ENGATI';

export const API_AUTH_KEY = 'ApiKeyAuth';

export const ENGATI_PLATFORM_BOT = 'ENGATI_BOT_PLATFORM';

export const ENGATI_PLATFORM_CONNECT = 'ENGATI_CONNECT';

export const BASIC_AUTH_TYPE = 'BasicAuth';

export const stripeCurrencies = [
	{ id: 'usd', label: 'USD', zeroDecimal: false },
	{ id: 'aed', label: 'AED', zeroDecimal: false },
	{ id: 'afn', label: 'AFN', zeroDecimal: false },
	{ id: 'all', label: 'ALL', zeroDecimal: false },
	{ id: 'amd', label: 'AMD', zeroDecimal: false },
	{ id: 'ang', label: 'ANG', zeroDecimal: false },
	{ id: 'aoa', label: 'AOA', zeroDecimal: false },
	{ id: 'ars', label: 'ARS', zeroDecimal: false },
	{ id: 'aud', label: 'AUD', zeroDecimal: false },
	{ id: 'awg', label: 'AWG', zeroDecimal: false },
	{ id: 'azn', label: 'AZN', zeroDecimal: false },
	{ id: 'bam', label: 'BAM', zeroDecimal: false },
	{ id: 'bbd', label: 'BBD', zeroDecimal: false },
	{ id: 'bdt', label: 'BDT', zeroDecimal: false },
	{ id: 'bgn', label: 'BGN', zeroDecimal: false },
	{ id: 'bif', label: 'BIF', zeroDecimal: true },
	{ id: 'bmd', label: 'BMD', zeroDecimal: false },
	{ id: 'bnd', label: 'BND', zeroDecimal: false },
	{ id: 'bob', label: 'BOB', zeroDecimal: false },
	{ id: 'brl', label: 'BRL', zeroDecimal: false },
	{ id: 'bsd', label: 'BSD', zeroDecimal: false },
	{ id: 'bwp', label: 'BWP', zeroDecimal: false },
	{ id: 'bzd', label: 'BZD', zeroDecimal: false },
	{ id: 'cad', label: 'CAD', zeroDecimal: false },
	{ id: 'cdf', label: 'CDF', zeroDecimal: false },
	{ id: 'chf', label: 'CHF', zeroDecimal: false },
	{ id: 'clp', label: 'CLP', zeroDecimal: true },
	{ id: 'cmy', label: 'CMY', zeroDecimal: false },
	{ id: 'cop', label: 'COP', zeroDecimal: false },
	{ id: 'crc', label: 'CRC', zeroDecimal: false },
	{ id: 'cve', label: 'CVE', zeroDecimal: false },
	{ id: 'czk', label: 'CZK', zeroDecimal: false },
	{ id: 'djf', label: 'DJF', zeroDecimal: true },
	{ id: 'dkk', label: 'DKK', zeroDecimal: false },
	{ id: 'dop', label: 'DOP', zeroDecimal: false },
	{ id: 'dzd', label: 'DZD', zeroDecimal: false },
	{ id: 'egp', label: 'EGP', zeroDecimal: false },
	{ id: 'etb', label: 'ETB', zeroDecimal: false },
	{ id: 'eur', label: 'EUR', zeroDecimal: false },
	{ id: 'fjd', label: 'FJD', zeroDecimal: false },
	{ id: 'fap', label: 'FAP', zeroDecimal: false },
	{ id: 'gbp', label: 'GBP', zeroDecimal: false },
	{ id: 'gel', label: 'GEL', zeroDecimal: false },
	{ id: 'gip', label: 'GIP', zeroDecimal: false },
	{ id: 'gmd', label: 'GMD', zeroDecimal: false },
	{ id: 'gnf', label: 'GNF', zeroDecimal: true },
	{ id: 'gtq', label: 'GTQ', zeroDecimal: false },
	{ id: 'gyd', label: 'GYD', zeroDecimal: false },
	{ id: 'hkd', label: 'HKD', zeroDecimal: false },
	{ id: 'hnl', label: 'HNL', zeroDecimal: false },
	{ id: 'hrk', label: 'HRK', zeroDecimal: false },
	{ id: 'htg', label: 'HTG', zeroDecimal: false },
	{ id: 'huf', label: 'HUF', zeroDecimal: false },
	{ id: 'idr', label: 'IDR', zeroDecimal: false },
	{ id: 'ils', label: 'ILS', zeroDecimal: false },
	{ id: 'inr', label: 'INR', zeroDecimal: false },
	{ id: 'isk', label: 'ISK', zeroDecimal: false },
	{ id: 'jmd', label: 'JMD', zeroDecimal: false },
	{ id: 'jpy', label: 'JPY', zeroDecimal: true },
	{ id: 'kes', label: 'KES', zeroDecimal: false },
	{ id: 'kgs', label: 'KGS', zeroDecimal: false },
	{ id: 'khr', label: 'KHR', zeroDecimal: false },
	{ id: 'kmf', label: 'KMF', zeroDecimal: true },
	{ id: 'krw', label: 'KRW', zeroDecimal: true },
	{ id: 'kyd', label: 'KYD', zeroDecimal: false },
	{ id: 'kzd', label: 'KZD', zeroDecimal: false },
	{ id: 'lak', label: 'LAK', zeroDecimal: false },
	{ id: 'lbp', label: 'LBP', zeroDecimal: false },
	{ id: 'lkr', label: 'LKR', zeroDecimal: false },
	{ id: 'lrd', label: 'LRD', zeroDecimal: false },
	{ id: 'lsl', label: 'LSL', zeroDecimal: false },
	{ id: 'mad', label: 'MAD', zeroDecimal: false },
	{ id: 'mdl', label: 'MDL', zeroDecimal: false },
	{ id: 'mga', label: 'MGA', zeroDecimal: true },
	{ id: 'mkd', label: 'MKD', zeroDecimal: false },
	{ id: 'mmk', label: 'MMK', zeroDecimal: false },
	{ id: 'mnt', label: 'MNT', zeroDecimal: false },
	{ id: 'mop', label: 'MOP', zeroDecimal: false },
	{ id: 'mro', label: 'MRO', zeroDecimal: false },
	{ id: 'mur', label: 'MUR', zeroDecimal: false },
	{ id: 'mvr', label: 'MVR', zeroDecimal: false },
	{ id: 'mwk', label: 'MWK', zeroDecimal: false },
	{ id: 'mxn', label: 'MXN', zeroDecimal: false },
	{ id: 'myr', label: 'MYR', zeroDecimal: false },
	{ id: 'mzn', label: 'MZN', zeroDecimal: false },
	{ id: 'nad', label: 'NAD', zeroDecimal: false },
	{ id: 'nio', label: 'NIO', zeroDecimal: false },
	{ id: 'nok', label: 'NOK', zeroDecimal: false },
	{ id: 'npr', label: 'NPR', zeroDecimal: false },
	{ id: 'nzd', label: 'NZD', zeroDecimal: false },
	{ id: 'pab', label: 'PAB', zeroDecimal: false },
	{ id: 'pen', label: 'PEN', zeroDecimal: false },
	{ id: 'pgk', label: 'PGK', zeroDecimal: false },
	{ id: 'php', label: 'PHP', zeroDecimal: false },
	{ id: 'pkr', label: 'PKR', zeroDecimal: false },
	{ id: 'pln', label: 'PLN', zeroDecimal: false },
	{ id: 'pyg', label: 'PYG', zeroDecimal: true },
	{ id: 'qar', label: 'QAR', zeroDecimal: false },
	{ id: 'ron', label: 'RON', zeroDecimal: false },
	{ id: 'rsd', label: 'RSD', zeroDecimal: false },
	{ id: 'rub', label: 'RUB', zeroDecimal: false },
	{ id: 'rwf', label: 'RWF', zeroDecimal: true },
	{ id: 'sar', label: 'SAR', zeroDecimal: false },
	{ id: 'sbd', label: 'SBD', zeroDecimal: false },
	{ id: 'scr', label: 'SCR', zeroDecimal: false },
	{ id: 'sek', label: 'SEK', zeroDecimal: false },
	{ id: 'sgd', label: 'SGD', zeroDecimal: false },
	{ id: 'shp', label: 'SHP', zeroDecimal: false },
	{ id: 'sll', label: 'SLL', zeroDecimal: false },
	{ id: 'sos', label: 'SOS', zeroDecimal: false },
	{ id: 'srd', label: 'SRD', zeroDecimal: false },
	{ id: 'std', label: 'STD', zeroDecimal: false },
	{ id: 'szl', label: 'SZL', zeroDecimal: false },
	{ id: 'thb', label: 'THB', zeroDecimal: false },
	{ id: 'tjs', label: 'TJS', zeroDecimal: false },
	{ id: 'top', label: 'TOP', zeroDecimal: false },
	{ id: 'try', label: 'TRY', zeroDecimal: false },
	{ id: 'ttd', label: 'TTD', zeroDecimal: false },
	{ id: 'twd', label: 'TWD', zeroDecimal: false },
	{ id: 'tzs', label: 'TZS', zeroDecimal: false },
	{ id: 'uah', label: 'UAH', zeroDecimal: false },
	{ id: 'ugx', label: 'UGX', zeroDecimal: true },
	{ id: 'uyu', label: 'UYU', zeroDecimal: false },
	{ id: 'uzs', label: 'UZS', zeroDecimal: false },
	{ id: 'vnd', label: 'VND', zeroDecimal: true },
	{ id: 'vuv', label: 'VUV', zeroDecimal: true },
	{ id: 'wst', label: 'WST', zeroDecimal: false },
	{ id: 'xaf', label: 'XAF', zeroDecimal: true },
	{ id: 'xcd', label: 'XCD', zeroDecimal: false },
	{ id: 'xof', label: 'XOF', zeroDecimal: true },
	{ id: 'xpf', label: 'XPF', zeroDecimal: true },
	{ id: 'yer', label: 'YER', zeroDecimal: false },
	{ id: 'zar', label: 'ZAR', zeroDecimal: false },
	{ id: 'zmw', label: 'ZMW', zeroDecimal: false },
];
export const ENGATI_RECAPTCHA_SITE_KEY = '6LcLg2gaAAAAAKbZfgywiwqq7_LuT841NiU_k0TZ';

export const PLAN_UNASSIGNED = 'PLAN_UNASSIGNED';

export const ENFORCED = 'ENFORCED';

export const PAYMENT_AWAITED = 'PAYMENT_AWAITED';

export const CREDITS_EXHAUSTED = 'CREDITS_EXHAUSTED';

export const PLG_PLAN_EXPIRED = 'PLG_PLAN_EXPIRED';

export enum BRANDING_URL {
	engati = 'https://s3.ap-south-1.amazonaws.com/branding-resources/default',
}

export const ATTRIBUTE_NAME_REGEX = new RegExp(
	['^attr_', '^context[.]', '^conversation[.]', '^user[.]', '^livechat_', '^[+]', '^[-]', '[(]', '[)]'].join('|')
);

export const SHOPIFY_APP_STORE_LINK = 'https://apps.shopify.com/';

export const DEFAULT_FAVICON_URL = 'https://s3.ap-south-1.amazonaws.com/branding-resources/default/favicon';

export const WEEK_DAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
export const WEEK_DAYS_LONG = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const INTEGRATION_REDIRECT_URL = '/integrations/cloud-integrations';

export const BUILDER_BUNDLE = [
	'FAQ',
	'FLOW_ATTRIBUTES',
	'CATEGORY',
	'FLOW',
	'RESPONSE',
	'DATASOURCE',
	'RESPONSE_DATA_SOURCE_MAPPING',
	'DATA_SOURCE_HISTORY',
	'ENTITY',
	'CUSTOM_ENTITY',
	'RESPONSE_ENTITY_MAPPING',
	'STATEMENT',
	'SYNONYMS',
	'STOPWORDS',
	'CAMPAIGN',
	'CAMPAIGN_MESSAGE',
	'SHOPPING',
	'LOCALISATION_SETTINGS',
	'LOCALISATION_PARAMS',
	'BROADCAST_STORE',
	'BOT',
	'VISUAL_MODELLER',
	'MESSAGE_OPTIONS',
	'AGENT_CATEGORY_MAPPING',
	'AGENT_CHANNEL_MAPPING',
	'ROUTING_RULE_SET',
	'COGNITIVE_SEARCH',
	'TEAM_SETTINGS',
	'GENERAL_SETTINGS',
	'QUICK_REPLIES',
	'ACTION_PATH_MAPPER',
	'PROMPTS',
	'COGNITIVE_SEARCH_CONFIG',
];

export const CONFIGURE_BUNDLE_MAP = [
	{ id: 'BOT_AND_CONFIG', label: 'Bot Config' },
	{ id: 'WEB_DISPLAY_CONFIG', label: 'Web Display' },
	{ id: 'VOICE_CONFIG', label: 'Voice Config' },
	{ id: 'LAUNCH_CARD_CONFIG', label: 'Launch Card Config' },
	{ id: 'FORMATTING_GROUP', label: 'Formatting' },
	{ id: 'SECURITY_CONFIG', label: 'Security' },
];
export const NEW_MESSAGE_INDICATOR_ELEMENT_ID = 'new-message-indication-divider';
export const NO_PAGE_FOUND_FAV_ICON = 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/generic/favicon';
export const EXTERNAL_LC_WEBHOOK = `${REACT_APP_BASE_URL}/livechat/webhook/`;
export const THUMB_GESTURE_UP_ICON = 'https://branding-resources.s3.ap-south-1.amazonaws.com/images/thumbsup.svg';
export const THUMB_GESTURE_DOWN_ICON = 'https://branding-resources.s3.ap-south-1.amazonaws.com/images/thumbsdown.svg';
export const PASSWORD_MAXIMUM_CHARACTERS = 32;
export const SALESTRAIL_PASSWORD_MAXIMUM_CHARACTERS = 512;
export const MESSAGE_LIST_MAXIMUM_CHARACTERS = 50;
export const MESSAGE_LIST_USER_NAME_MAXIMUM_CHARACTERS = 32;
export const MOBILE_VIEW_BREAKPOINT = 1136;
export const EMAIL_MAXIMUM_CHARACTERS = 100;
export const TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT = 3;
export const TEXT_MEDIA_TEMPLATE_V2_BUTTON_LIMIT = 10;
export const CAROUSEL_TEMPLATE_BUTTON_LIMIT = 2;
export const EXCLUDED_ATTRIBUTE_LIST = [
	'bot.after_tomorrow',
	'bot.feedback_on_resolution',
	'bot.today',
	'bot.tomorrow',
	'conversation.due_by',
	'conversation.id',
	'conversation.last_agent',
	'conversation.resolved_on',
	'conversation.topic',
	'conversation.user_feedback',
	'conversation.user_feedback_capture',
	'user.gorgias.verified',
	'user.gorgias.email',
	'user.gorgias',
	'user.last_query',
	'user.priority',
	'user.zendesk.verified',
	'user.zendesk.email',
	'user.zendesk',
	'user.platform',
	'user_web',
];
export const PAGING_DEFAULT_OBJ = {
	page: 1,
	size: 25,
	totalPages: 1,
	totalElements: 1,
};

export const PRODUCT_VERTICAL_CODE_MAPPER: any = {
	partner: 'Partner',
	shopify: 'Shopify / E-commerce',
	woocommerce: 'WooCommerce / E-commerce',
	web_option1: 'SME',
	web_option2: 'SME',
	web_option3: 'ABM',
	whatsapp_option1: 'WhatsApp',
	whatsapp_option2: 'WhatsApp',
	whatsapp_option3: 'ABM',
};

export const WHATSAPP_PROVIDER_TO_PLATFORM_NAME_MAPPER: any = {
	'360Dialog': 'DIALOG360',
	'WhatsApp Cloud': 'WHATSAPP',
	Kaleyra: 'KALEYRA',
	Airtel: 'AIRTEL',
	common_whatsapp: 'COMMON_WHATSAPP',
};
export enum WHATSAPP_PROVIDERS {
	'dialog360' = '360Dialog',
	'whatsAppCloud' = 'WhatsApp Cloud',
	'common_whatsapp' = 'common_whatsapp',
}
export const waNodesAllowedProviders = [WHATSAPP_PROVIDERS.dialog360, WHATSAPP_PROVIDERS.common_whatsapp];
export const templateMessageAllowedProviders = [
	WHATSAPP_PROVIDERS.dialog360,
	WHATSAPP_PROVIDERS.whatsAppCloud,
	WHATSAPP_PROVIDERS.common_whatsapp,
];

export const circularButtonCss = {
	':focus': {
		background: '#AFAFAF',
	},
	':hover': {
		background: '#AFAFAF',
	},
	height: '2.5rem',
	width: '2.5rem',
};

export enum BROWSER_NAMES {
	CHROME = 'chrome',
	FIREFOX = 'firefox',
}

export const getBrowserName = () => {
	const { userAgent } = navigator;
	if (userAgent.match(/chrome|chromium|crios/i)) {
		return 'chrome';
	}
	if (userAgent.match(/firefox|fxios/i)) {
		return 'firefox';
	}

	return '';
};

export const tooltipPopoverOverrides = ({
	backgroundColor,
	style,
	maxWidth,
}: {
	backgroundColor?: string;
	style?: IObjProps;
	maxWidth?: string;
}) => ({
	Body: {
		style: ({ $theme }: any) => ({
			backgroundColor: backgroundColor || $theme.colors.primaryB,
			boxShadow: $theme.lighting.shadow400,
			borderTopLeftRadius: $theme.borders.radius300,
			borderTopRightRadius: $theme.borders.radius300,
			borderBottomRightRadius: $theme.borders.radius300,
			borderBottomLeftRadius: $theme.borders.radius300,
			maxWidth,
			...(style?.Body || style),
		}),
	},
	Inner: {
		style: ({ $theme }: any) => ({
			borderTopLeftRadius: $theme.borders.radius300,
			borderTopRightRadius: $theme.borders.radius300,
			borderBottomRightRadius: $theme.borders.radius300,
			borderBottomLeftRadius: $theme.borders.radius300,
			backgroundColor: backgroundColor || $theme.colors.primaryB,
			...style?.Inner,
		}),
	},
	Arrow: {
		style: ({ $theme }: any) => ({
			boxShadow: $theme.lighting.shadow400,
			backgroundColor: backgroundColor || $theme.colors.primaryB,
			...style?.Arrow,
		}),
	},
});

export const USER_DETAILS = {
	'User Name': 'username',
	'First Name': 'first_name',
	Email: 'email',
	'Joined On': 'created_at',
	'Last Active': 'last_active_at',
	'Phone Number': 'phone_no',
	Account: 'account',
	Title: 'title',
	'Time Zone': 'user_info_attribute_time_zone',
	'Web Page': 'user_info_attribute_web_page',
	'Web Url': 'user_info_attribute_web_url',
	'User Handle': 'user_info_attribute_channel_user_handle',
};

export const ENGATI_MESSAGE_TEMPLATE_DELAY_REASONS_FROM_META =
	'https://developers.facebook.com/docs/whatsapp/message-templates/guidelines';

export const ENGATI_ANDROID_PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.engati.mobileapp';

export const ENGATI_IOS_APP_STORE_LINK = 'https://apps.apple.com/in/app/engati/id1596931607';

export const CHANNELS_NOT_INTEGRATED = [
	CHANNELS.VIBER,
	CHANNELS.MSFT,
	CHANNELS.SKYPE_FOR_BUSINESS,
	CHANNELS.IM,
	CHANNELS.API_TRIGGER,
];

export const SHOPIFY_CHANNELS = [
	CHANNELS.WEB,
	CHANNELS.WHATSAPP,
	CHANNELS.INSTAGRAM,
	CHANNELS.FACEBOOK,
	CHANNELS.EMAIL,
	CHANNELS.GBM,
];
export const WA_ACCELERATOR_CHANNELS = [CHANNELS.WHATSAPP];

export const WA_PLG_CHANNELS = [CHANNELS.WHATSAPP];

export const SHOPIFY_CHANNEL_ORDER: any = {
	[CHANNELS.WEB]: 1,
	[CHANNELS.WHATSAPP]: 2,
	[CHANNELS.INSTAGRAM]: 3,
	[CHANNELS.FACEBOOK]: 4,
	[CHANNELS.EMAIL]: 5,
	[CHANNELS.GBM]: 6,
};

export const BLOCK_BROADCAST_USER_DOWNLOAD_BEFORE = new Date('2022-03-16').toISOString();

export enum ADDITIONAL_ATTRIBUTES_CATEGORY {
	custom = 'CUSTOM',
	system = 'SYSTEM',
}

export enum PIN_LEVEL {
	user = 'USER',
	admin = 'ADMIN',
}

export const QUICK_GUIDE_ACCESS_ROLES = [
	ENGATI_ROLES.ROLE_CONNECT_OWNER,
	ENGATI_ROLES.ROLE_CONNECT_SUPERVISOR,
	ENGATI_ROLES.ROLE_CONNECT_BOT_BUILDER,
];

export const getScopeByApp = function (appName: string) {
	let scope;
	switch (appName) {
		case 'facebook':
			scope =
				'public_profile, email, pages_messaging, pages_show_list, pages_manage_metadata, pages_read_user_content, pages_manage_engagement, pages_read_engagement, business_management';
			break;
		case 'whatsapp':
			scope =
				'public_profile, email, business_management, catalog_management,whatsapp_business_management, whatsapp_business_messaging';
			break;
		case 'whatsAppCloud':
			scope = 'public_profile, whatsapp_business_messaging, whatsapp_business_management';
			break;
		case 'ads':
			scope = 'ads_management, ads_read, business_management, public_profile';
			break;
		default:
			scope =
				'public_profile, email, pages_messaging, pages_show_list, pages_manage_metadata, instagram_basic, instagram_manage_messages, pages_read_engagement, instagram_manage_comments, business_management';
	}

	return scope;
};

export const ONE_VIEW_LITE = 'oneviewlite';

export enum NOTIFICATION_MESSAGES_FREQUENCY {
	weekly = 'WEEKLY',
	daily = 'DAILY',
	monthly = 'MONTHLY',
}

export enum INTEGRATION_CHANNELS {
	shopify = 'shopify',
	woocommerce = 'woocommerce',
	shiprocket = 'shiprocket',
	razorpay = 'razorpay',
	googleAnalytics = 'google-analytics',
	googleTranslate = 'google-translate',
	gorgias = 'gorgias',
	zendesk = 'zendesk',
	salesforce = 'salesforce',
	freshdesk = 'freshdesk',
	sms = 'sms',
	eSenseGPT = 'eSenseGPT',
	gpt = 'GPT',
}

export const ATTR_EXTRACTOR_REGEX = /{{.*?}}/g;

export enum TEMPLATE_MESSAGE_SCOPE {
	owner = 'OWNER',
	supervisor = 'SUPERVISOR',
	agent = 'AGENT',
}
export const smToolbarOptions = ['bold', 'italic', 'underline', 'strike', 'link'];

export enum KEYBOARD_KEY {
	BACKSPACE = 8,
	ENTER = 13,
}

export const DEFAULT_FILTER_CONSTANT: IMessageFilters = {
	facets: {},
	sort: 'lastMessageTime,desc',
};

export const CONVERSATION_LOAD_COUNT = 10;

export const MAX_PAGINATION_NUMBERS = 5;

export enum WHATSAPP_ACCOUNT_STATUS {
	CONNECTED = 'connected',
	FLAGGED = 'flagged',
	RATE_LIMITED = 'rate_limited',
	INVALID_API_KEY = 'invalid_api_key',
	UNREGISTERED = 'unregistered',
	UNINITIALIZED = 'uninitialized',
	DISCONNECTED = 'disconnected',
	CONNECTING = 'connecting',
	PENDING = 'pending',
	DELETED = 'deleted',
	MIGRATED = 'migrated',
	BANNED = 'banned',
	RESTRICTED = 'restricted',
	UNKNOWN = 'unknown',
	UNVERIFIED = 'unverified',
}

export const BEST_PRACTICES_WA_TEMPLATE_MESSAGE_LINK = 'https://help.engati.com/best-practices-for-wa-template-message';

export enum ENGATI_PLANS {
	ENGATI_PRO_2024 = 'ENGATI_PRO_2024',
	ENGATI_GROWTH_2024 = 'ENGATI_GROWTH_2024',
	WA_ACCELERATOR_TRIAL = 'WA_ACCELERATOR_TRIAL',
	WA_ACCELERATOR = 'WA_ACCELERATOR',
	ENGATI_ENTERPRISE = 'ENGATI_ENTERPRISE',
	ENGATI_WHATSAPP_BASE = 'ENGATI_WHATSAPP_BASE',
	ENGATI_WHATSAPP_PRO = 'ENGATI_WHATSAPP_PRO',
	ENGATI_WHATSAPP_GROWTH = 'ENGATI_WHATSAPP_GROWTH',
	ENGATI_WA_RAPID = 'ENGATI_WA_RAPID',
	ENGATI_ENTERPRISE_2024 = 'ENGATI_ENTERPRISE_2024',
}

export enum PRICING_PAGE_PLAN_HIGHLIGHTERS {
	MOST_POPULAR = 'MOST_POPULAR',
	MOST_PREFERRED = 'MOST_PREFERRED',
	ADVANCED = 'ADVANCED',
	ACTIVE = 'ACTIVE',
	DISABLED = 'DISABLED',
	RECOMMENDED = 'RECOMMENDED',
}

export enum SEARCH_TAGS {
	USERS = 'users',
	MESSAGES = 'messages',
}

export enum MESSAGE_STATES {
	READ = 'READ',
	DELIVERED = 'DELIVERED',
	SENT = 'SENT',
	FAILED = 'FAILED',
	AWAITED = 'AWAITED',
	SENDING = 'SENDING',
}

export const CLIENT_EMAIL = 'clientEmail';

export const BOT_LAUNCH_ICON =
	'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/generic-icons-svg/engati_logo.svg';
export const MAX_FILE_NAME_LENGTH = 240;
export const CSRF_TOKEN_INTERVAL = 1800000;

export enum CAROUSEL_SOURCE_TYPES {
	STATIC = 'STATIC',
	DYNAMIC = 'DYNAMIC',
	DYNAMIC_CART = 'DYNAMIC_CART',
}
export enum FILTER_TYPES {
	date = 'date',
	select = 'select',
}

export interface IMessageFacets {
	channelsSelected?: Array<ISelectValue>;
	dateSelected?: Array<any>;
	slaFilterSelected?: Array<ISelectValue>;
	managedByFilter?: Array<ISelectValue>;
	statusSelected?: Array<ISelectValue>;
	intentsSelected?: Array<ISelectValue>;
	tagsSelected?: Array<ISelectValue>;
	sentimentsSelected?: Array<ISelectValue>;
	sentimentAttributeId?: string;
	customFilterSelected?: IObjProps;
}

export const WINDOWS_SCROLL_BAR_CSS = {
	'::-webkit-scrollbar': {
		width: '0.5rem',
		height: '0.475rem',
	},
	'::-webkit-scrollbar-thumb ': {
		borderRadius: '0.3rem',
		backgroundColor: '#C4C4C4',
	},
};

export enum SCROLL_TYPE {
	CUSTOM_BOTTOM = 'CUSTOM_BOTTOM',
	CUSTOM_TOP = 'CUSTOM_TOP',
	BOTTOM = 'BOTTOM',
	TOP = 'TOP',
}

export const ENTITY_REGEX = /__([0-9a-f]{24}):(.*?)__/g;

export enum GA4WorkFlowKeys {
	GET_LIST_OF_ACCOUNTS = 'GA4_GET_LIST_OF_ACCOUNTS',
	TO_CREATE_PROPERTY = 'GA4_CREATE_PROPERTY',
	GET_LIST_OF_PROPERTY = 'GA4_GET_LIST_OF_PROPERTY',
	GET_LIST_OF_DATASTREAMS = 'GA4_GET_LIST_OF_DATASTREAMS',
	CREATE_API_SECRET = 'GA4_CREATE_API_SECRET',
	CREATE_DATASTREAM = 'GA4_CREATE_DATASTREAM',
}

export const filter = 'parent%3Aaccounts%2F';

export const integrationKey = 'GA4';

export const WA_TEMPLATE_ENGATI_DOMAIN = 'api.engati.com';

export const WA_TEMPLATE_PLACEHOLDER_DOMAIN = '<YOUR-API-DOMAIN>';

export const WA_ACCELERATOR_DEFAULT_MESSAGE = 'Hi! How can i help you?';

export const QUICK_FILTER_CONSTANT = {
	myConversations: true,
	unreadMessages: false,
	starredConversations: false,
	priority: true,
};

export const QUICK_FILTER_WA_ACCELERATOR_CONSTANT = {
	...QUICK_FILTER_CONSTANT,
	myConversations: false,
};

export const SOCIAL_PROVIDERS_GA4_TRACKING_IDS: IObjProps = {
	GOOGLE: LoginMethods.GOOGLE,
	FACEBOOK: LoginMethods.FACEBOOK,
	O365: LoginMethods.OFFICE_365,
};

export const BOT_AUTOMATION_PAUSE_LIMIT = 24;
export const DATE_MONTH_YEAR_FORMAT = 'dd MMM yyyy';
export const HOUR_MIN_SEC_FORMAT = 'HH:mm:ss';
export enum GOOGLE_ANALYTICS_PROVIDER {
	UA = 'UA',
	GA4 = 'GA4',
}
export const GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS';

export const SUCCESS_WITH_CAPITAL_S = 'Success';
export const YEAR_MONTH_DATE_HOUR_MIN_SEC_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

export const LLM_INTEGRATION_KEYS = [LLM_PROVIDERS.OPENAI, LLM_PROVIDERS.AZURE_OPENAI, LLM_PROVIDERS.GOOGLE_PALM];
export const FROM = 'from';
export const TO = 'to';
export const PLUS = '+';
export const MINUS = '-';
export const MAXIMUM_SELF_RECHARGE_AMOUNT = 999999;

export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const LOCALE_EN = 'en';

export const API_GATEWAY_URL = `${REACT_APP_BASE_URL}/api-gateway`;

export const START_NEW_EMAIL_TOASTER_AUTO_HIDE_DURATION = 15000;

export const INTERNAL_TESTING_UTM_CAMPAIGN = 'engati-internal-testing';
export const EXTERNAL_INTEGRATION_PROVIDER = 'integry';

export enum MAX_ZOOM_IN {
	WINDOWS = 1.5,
	MAC = 2.49,
}
export const WA_EXPERIMENT_URL = 'https://developers.facebook.com/docs/whatsapp/cloud-api/guides/experiments/';
export const PAYMENT_TERMS_CONDITION_LINK = 'https://www.engati.com/important-terms-and-conditions';

export const NUMBER_OF_EMAILS_FOR_PAYMENT_NOTIFICATION = 3;
export const GLOBAL_ZOOM_SCALE = 'globalZoomScale';

export const LAST_THREE_MONTHS = addMonths(new Date(), -3);

export const getChannelObject = (t: Function, channelKey: string) => {
	const channels: IObjProps = {
		whatsapp: { id: 'whatsapp', label: t('components:importBroadcastList.whatsapp') },
		rcs: { id: 'rcs', label: t('components:importBroadcastList.rcs') },
	};

	return channels[channelKey];
};

export const PATH_BUILDER_NUDGE_PARAM = 'welcomeJourneyPathBuilder';
export const PLAN_SUCCESS_PARAM = 'planPaymentSuccess';
export const PLAN_FAILURE_PARAM = 'planPaymentFailure';
export const EXPERT_ASSISTANCE_PARAM = 'expertAssistanceSuccess';
export const DIVIDER = 'divider';

export const OFFERING_TO_PLAN_NAME_MAP: IObjProps = {
	[ENGATI_PLANS.ENGATI_WHATSAPP_PRO]: 'WhatsApp Pro',
	[ENGATI_PLANS.ENGATI_WHATSAPP_GROWTH]: 'WhatsApp Growth',
	[ENGATI_PLANS.ENGATI_WHATSAPP_BASE]: 'WhatsApp Base',
	[ENGATI_PLANS.ENGATI_WA_RAPID]: 'WhatsApp Rapid',
	[ENGATI_PLANS.WA_ACCELERATOR_TRIAL]: 'WhatsApp Accelerator',
	[ENGATI_PLANS.WA_ACCELERATOR]: 'WhatsApp Accelerator',
};

export const INTEGRATION_TYPES: IObjProps = {
	native: 'native',
	external: 'external',
	deep: 'deep',
};

export const DEEP_INTEGRATION_KEYS: IObjProps = {
	WOOCOMMERCE: 'WOOCOMMERCE',
	SHOPIFY: 'SHOPIFY',
	RAZORPAY: 'RAZORPAY',
	SHIPROCKET: 'SHIPROCKET',
};

export const NATIVE_INTEGRATION_KEYS: IObjProps = {
	GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
	ESENSEGPT: 'eSenseGPT',
	WA_PAYMENT: 'WA_PAYMENT',
	GOOGLE_ADMIN: 'GOOGLE_ADMIN',
	SALESTRAIL: 'SALESTRAIL',
};

export const LLM_FEATURE_TYPES: IObjProps = {
	BASIC: 'BASIC',
	ADVANCE: 'ADVANCE',
	CUSTOM: 'CUSTOM',
};

export const LLM_FEATURES: IObjProps = {
	LLM_FAQ_RESPONSE: 'LLM_FAQ_RESPONSE',
	RAG_QUESTION_ANSWERING: 'RAG_QUESTION_ANSWERING',
	NUDGES_INTENT_SENTIMENT: 'NUDGES_INTENT_SENTIMENT',
	DYNAMIC_QUESTION_GENERATION: 'DYNAMIC_QUESTION_GENERATION',
	URL_SHORTENING: 'URL_SHORTENING',
	CONVERSATIONAL_DIALOGUE: 'CONVERSATIONAL_DIALOGUE',
	BULK_UPLOAD: 'BULK_UPLOAD',
	PRIORITIZE_DATA_SOURCES: 'PRIORITIZE_DATA_SOURCES',
	AUTO_REFRESH: 'AUTO_REFRESH',
	DOMAIN_BOT_REFERENCE: 'DOMAIN_BOT_REFERENCE',
	COMPLEX_PDF_SUPPORT: 'COMPLEX_PDF_SUPPORT',
	ACRONYM_SUPPORT: 'ACRONYM_SUPPORT',
	AGENT_ASSIST: 'AGENT_ASSIST',
	CSV_SUPPORT: 'CSV_SUPPORT',
	CUSTOM_PROMPTS: 'CUSTOM_PROMPTS',
	PRIORITIZE_DOCUMENT: 'PRIORITIZE_DOCUMENT',
};

export enum WA_ENGATI_TSP {
	DIALOG360 = '360DIALOG',
	META = 'META',
}

export const TIME_SLOTS = [
	{ id: '5_30', label: '05:30' },
	{ id: '6_0', label: '06:00' },
	{ id: '6_30', label: '06:30' },
	{ id: '7_0', label: '07:00' },
	{ id: '7_30', label: '07:30' },
	{ id: '8_0', label: '08:00' },
	{ id: '8_30', label: '08:30' },
	{ id: '9_0', label: '09:00' },
	{ id: '9_30', label: '09:30' },
	{ id: '10_0', label: '10:00' },
	{ id: '10_30', label: '10:30' },
	{ id: '11_0', label: '11:00' },
	{ id: '11_30', label: '11:30' },
	{ id: '12_0', label: '12:00' },
	{ id: '12_30', label: '12:30' },
	{ id: '13_0', label: '13:00' },
	{ id: '13_30', label: '13:30' },
	{ id: '14_0', label: '14:00' },
	{ id: '14_30', label: '14:30' },
	{ id: '15_0', label: '15:00' },
	{ id: '15_30', label: '15:30' },
	{ id: '16_0', label: '16:00' },
	{ id: '16_30', label: '16:30' },
	{ id: '17_0', label: '17:00' },
	{ id: '17_30', label: '17:30' },
	{ id: '18_0', label: '18:00' },
	{ id: '18_30', label: '18:30' },
	{ id: '19_0', label: '19:00' },
];

export const SUPPORT_TYPE_OPTIONS = () => [
	{
		id: 'whatsappCampaign',
		label: t('components:expertAssistanceModal.whatsappCampaign'),
	},
	{
		id: 'platformTraining',
		label: t('components:expertAssistanceModal.platformTraining'),
	},
	{
		id: 'onboardNumber',
		label: t('components:expertAssistanceModal.onboardNumber'),
	},
	{
		id: 'automatedWorkflow',
		label: t('components:expertAssistanceModal.automatedWorkflow'),
	},
];
