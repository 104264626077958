import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { CURRENCY_MAP, SUPPORT_TYPE_OPTIONS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { formSelectValidation } from 'shared/helpers/broadcastHelpers';

import { RootState } from 'store/rootReducer';
import { userPhoneNumber } from 'store/Users/selectors';

import { IMessageType } from './ExpertAssistanceModal';

interface ConfirmationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	dateValue: Date;
	selectedMessageType: IMessageType | undefined;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	dateValue,
	selectedMessageType,
}) => {
	const { t } = useTranslation(['components', 'errors', 'common']);
	const currency = useSelector((state: RootState) => state.Plan.details.data?.currencyName) || 'USD';

	const methods = useFormContext();

	const email = useSelector((state: RootState) => state.User.profile.data.email);
	const userPhoneNo = useSelector(userPhoneNumber);

	const { control } = methods;

	const AMOUNT: IObjProps = {
		USD: '10 USD',
		INR: 'INR 500',
	};

	const modalContent = (
		<>
			<Controller
				control={control}
				defaultValue={t('common:standardDateFormatterWithShortMonthName', {
					date: dateValue,
				})}
				name='date'
				render={({ ref, value, onChange }) => (
					<FormInput
						name='date'
						label={t('components:confirmationModal.date')}
						placeholder={t('components:confirmationModal.date')}
						type='text'
						disabled
						inputRef={ref}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<Controller
				control={control}
				defaultValue={t('common:standardTimeFormatter', {
					date: dateValue,
				})}
				name='time'
				render={({ ref, value, onChange }) => (
					<FormInput
						name='time'
						label={t('components:confirmationModal.time')}
						placeholder={t('components:confirmationModal.time')}
						type='text'
						disabled
						inputRef={ref}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name='email'
				defaultValue={email}
				render={({ ref, value }) => (
					<FormInput
						name='email'
						disabled
						label={t('pages:accountSettings.profile.email')}
						placeholder={t('pages:accountSettings.profile.enterEmail')}
						type='email'
						inputRef={ref}
						value={value}
					/>
				)}
			/>
			<Controller
				control={control}
				defaultValue={userPhoneNo}
				name='userPhoneNo'
				render={({ ref, value, onChange }) => (
					<FormInput
						name='userPhoneNo'
						label={t('pages:accountSettings.profile.phone')}
						placeholder={t('pages:accountSettings.profile.enterPhone')}
						type='text'
						disabled
						inputRef={ref}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<p>
				<h5>
					{t('components:confirmationModal.subHeading', {
						amount: currency === CURRENCY_MAP.USD ? AMOUNT.USD : AMOUNT.INR,
					})}
				</h5>
			</p>
		</>
	);

	return (
		<ActionModal
			heading={t('components:confirmationModal.heading')}
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onConfirm}
			confirmBtnLabel={t('components:confirmationModal.book')}
			closeBtnLabel={t('components:confirmationModal.edit')}
			isConfirmBtnDisabled={false}
		>
			<Controller
				control={control}
				name='messageType'
				rules={{
					validate: (value) => formSelectValidation(value),
					required: t('errors:requiredErrorMessage').toString(),
				}}
				render={({ ref, value }) => (
					<FormSelect
						name='messageType'
						inputRef={ref}
						disabled
						label={t('components:confirmationModal.subject')}
						options={SUPPORT_TYPE_OPTIONS()}
						selectedValue={value || selectedMessageType}
						onChange={() => {}}
						popupIndex={100}
					/>
				)}
			/>
			{modalContent}
		</ActionModal>
	);
};

export default ConfirmationModal;
